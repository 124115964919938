// @flow
import { TextField } from '@src/formik-utils/FormFields';
import { SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/default-commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import React from 'react';

const SPDefaultCommitValueContainer = () => {
  const { backendFieldName, displayName } = SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.COMMIT_AMOUNT
  );

  return (
    <TextField
      disableOnFormErrors={false}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
    />
  );
};

export default SPDefaultCommitValueContainer;
