// @flow
import {
  AWS,
  DEDICATED_CLUSTER_TYPE,
  ENTERPRISE_CLUSTER_TYPE,
  PEERED_VPC,
  RATE_CARD_VERSION_4_11_2024,
} from '@src/constants';
import {
  getLinkForOrganizationsPage,
  getLinkForSPDefaultCommitDetailsPage,
  getLinkForStreamingProjectsHomePage,
  getLinkForStreamingProjectsSPPage,
} from '@streaming-projects/links';
import { StyledSpan } from '@src/common-utils/styledComponents';
import { Link } from 'react-router-dom';
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { BACKEND_FIELDNAMES } from './enums';
import { CLUSTER_RESOURCES_GRID_FIELDNAMES } from './orgs/enums';

export const getRCVersionOptionsFromRCs = (rcVersions, isRenewal) => {
  const rcVersionsOptions = [];
  for (const rc of rcVersions) {
    if (isRenewal && rc.name === RATE_CARD_VERSION_4_11_2024) {
      continue;
    }

    const actionable = rc?.actionable ?? true;
    rcVersionsOptions.push({
      name: rc.name,
      display_name: rc.label,
      shouldNotBeSelectable: !actionable,
    });
  }
  return rcVersionsOptions;
};

export const getOrgDefaultValues = (account) => {
  return {
    [BACKEND_FIELDNAMES.ACCOUNT_ID]: account[BACKEND_FIELDNAMES.ID],
    [BACKEND_FIELDNAMES.ACCOUNT_NAME]: account[BACKEND_FIELDNAMES.NAME],
  };
};

export const getSPOrgBreadcrumbs = (spOrgData) => {
  const orgId = spOrgData?.org?.id;
  const orgName = spOrgData?.org?.sfdc_org_name;
  return [
    <a href={getLinkForStreamingProjectsHomePage()} key="home">
      Home
    </a>,
    <StyledSpan key="sp-org"> Organization </StyledSpan>,
    <Link key="sp-org-name" to={getLinkForOrganizationsPage(orgId)}>
      {orgName}
    </Link>,
  ];
};

export const getSPStreamingProjectBreadcrumbs = (spOrgData, spStreamingProjectData) => {
  const orgId = spOrgData?.org?.id;
  return [
    ...getSPOrgBreadcrumbs(spOrgData),
    <StyledSpan key="sp-sp"> Streaming Project </StyledSpan>,
    <Link
      key="sp-sp-name"
      to={getLinkForStreamingProjectsSPPage(orgId, spStreamingProjectData?.sp?.sp_id)}
    >
      {spStreamingProjectData?.sp?.name}
    </Link>,
  ];
};

export const getWorkloadBreadcrumbs = (
  spOrgData,
  spStreamingProjectData,
  workLoadName,
  workloadType
) => {
  return [
    ...getSPStreamingProjectBreadcrumbs(spOrgData, spStreamingProjectData),
    <StyledSpan key="sp-workload"> Workload </StyledSpan>,
    <StyledSpan key="sp-workload-type"> {workloadType} </StyledSpan>,
    workLoadName,
  ];
};

export const getSPDefaultCommitDetailsBreadcrumbs = (spOrgData) => {
  const orgId = spOrgData?.org?.id;

  if (orgId) {
    return [
      ...getSPOrgBreadcrumbs(spOrgData),
      <StyledSpan key="sp-commit"> Commit </StyledSpan>,
      <Link key="org-default-commit-name" to={getLinkForSPDefaultCommitDetailsPage(orgId)}>
        default
      </Link>,
    ];
  }
};

export const getProviderRegionsMapFromMetaData = (apiData) => {
  const providerRegionsMap = {};
  const providerNameLabelMap = {};
  apiData.resource_region_details.region_details.forEach(
    ({ provider_label: providerLabel, provider_name: providerName, region }) => {
      providerRegionsMap[providerName] = {
        regions: region.reduce((acc, region) => {
          acc[region.region_name] = region.region_label;
          return acc;
        }, {}),
        providerLabel,
      };
      providerNameLabelMap[providerName] = providerLabel;
    }
  );

  return { providerRegionsMap, providerNameLabelMap };
};

export const shouldShowFFOption = (values, isUserAdmin) => {
  return (
    values[CLUSTER_RESOURCES_GRID_FIELDNAMES.PROVIDER] === AWS &&
    values[CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE] === DEDICATED_CLUSTER_TYPE &&
    values[CLUSTER_RESOURCES_GRID_FIELDNAMES.NETWORKING_TYPE] === PEERED_VPC &&
    isUserAdmin
  );
};

export const isExpandedInputNeeded = (clusterType, rateCardVersion) => {
  if (rateCardVersion === RATE_CARD_VERSION_4_11_2024) {
    return true;
  }
  const CLUSTER_TYPES_FOR_WHICH_EXPANDED_INPUTS_ARE_NEEDED_FOR_ALL_RCS = [
    DEDICATED_CLUSTER_TYPE,
    ENTERPRISE_CLUSTER_TYPE,
  ];

  return CLUSTER_TYPES_FOR_WHICH_EXPANDED_INPUTS_ARE_NEEDED_FOR_ALL_RCS.includes(clusterType);
};

export const getMonthsAndYearsTransformedMonthlySpendSummary = (monthlySpendSummary) => {
  const clonedMonthlySpendSummary = cloneDeep(monthlySpendSummary ?? []);
  // todo::SP Talk to BE and remove the need for the transformation to be done here
  clonedMonthlySpendSummary.forEach((item) => {
    item.yearlies.forEach((yearly) => {
      // Update calendar_year to year
      yearly.year = yearly.calendar_year;
      // Update calendar_month to month for each month
      yearly.months.forEach((month) => {
        month.month = month.calendar_month;
      });
    });
  });

  return clonedMonthlySpendSummary;
};
