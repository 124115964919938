// @flow
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import React from 'react';
import { Spacer } from '@presentational/spacing/Spacer';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { Grid } from 'semantic-ui-react';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/default-commit-details/config';

import SPDefaultCommitValueContainer from './fields/SPDefaultCommitValueContainer';

const SPDefaultCommitValueDetails = () => {
  const handleSave = async () => {
    // CTODO::SP Implement
  };

  return (
    <PaddedAndRaisedSegment>
      <Grid>
        <Grid.Column width={4}>
          <SPDefaultCommitValueContainer />
        </Grid.Column>
      </Grid>

      <Spacer y={40} />

      <GenericSaveResetButtons
        fieldsConfig={[SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP.get(BACKEND_FIELDNAMES.COMMIT_AMOUNT)]}
        onSaveHandler={handleSave}
      />
    </PaddedAndRaisedSegment>
  );
};

export default SPDefaultCommitValueDetails;
