// @flow
import { convertUTCToLocalDate } from '@src/common-utils/utils';
import { convertConfigArrayToMap } from '@src/configuration/utils';
import {
  DATE_INPUT_TYPE,
  SELECT_INPUT_TYPE,
  TEXT_INPUT_TYPE,
  TEXT_NUMBER_INPUT_TYPE,
} from '@src/formik-utils/consts';
import { BACKEND_FIELDNAMES, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { string, date, number } from 'yup';

/**
 * @typedef FormConfig
 * @type {Object}
 * @property {string} displayName
 * @property {string} backendFieldName
 * @property {string} inputType
 * @property {string} validation
 * @property {boolean} disabled
 */

/**
 * @type {FormConfig[]}
 */
export const SP_DEFAULT_COMMIT_DETAILS_CONFIG = [
  {
    displayName: FRONT_END_DISPLAY_NAMES.COMMIT_NAME,
    backendFieldName: BACKEND_FIELDNAMES.NAME,
    inputType: TEXT_INPUT_TYPE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SP_NAME).required(),
    disabled: true,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.DEAL_START_DATE,
    backendFieldName: BACKEND_FIELDNAMES.SP_START_DATE,
    validation: date().label(FRONT_END_DISPLAY_NAMES.SP_START_DATE).required(),
    inputType: DATE_INPUT_TYPE,
    disabled: true,
    inputTransformationFunc: convertUTCToLocalDate,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.DEAL_END_DATE,
    backendFieldName: BACKEND_FIELDNAMES.SP_END_DATE,
    validation: date().label(FRONT_END_DISPLAY_NAMES.SP_END_DATE).required(),
    inputType: DATE_INPUT_TYPE,
    disabled: true,
    inputTransformationFunc: convertUTCToLocalDate,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SUPPORT_TIER,
    backendFieldName: BACKEND_FIELDNAMES.SUPPORT_TIER,
    inputType: SELECT_INPUT_TYPE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SUPPORT_TIER).required(),
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.COMMIT_AMOUNT,
    backendFieldName: BACKEND_FIELDNAMES.COMMIT_AMOUNT,
    inputType: TEXT_NUMBER_INPUT_TYPE,
    validation: number().label(FRONT_END_DISPLAY_NAMES.COMMIT_AMOUNT),
  },
];

/**
 * @type {Map<string, FormConfig>}
 */
export const SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  SP_DEFAULT_COMMIT_DETAILS_CONFIG
);
