// @flow
import { MonthlySpendSummaryContainer } from '@components/common/MonthlySpendSummaryContainer';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { shouldSkipDataFetching } from '@src/common-utils/utils';
import { useGetSPDefaultCommitMonthlyPricingViewQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { getMonthsAndYearsTransformedMonthlySpendSummary } from '@streaming-projects/utils';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const SPDefaultCommitMonthlySummaryContainerWrapper = () => {
  const params = useParams();

  const dataFetchingArgs = useMemo(
    () => [{ ...params }, { skip: shouldSkipDataFetching(params) }],
    [params]
  );

  const dataFetchingFunction = useCallback(useGetSPDefaultCommitMonthlyPricingViewQuery, []);

  return (
    <DataFetcherContainer
      dataFetchingArgs={dataFetchingArgs}
      dataFetchingFunction={dataFetchingFunction}
    >
      {(data) => {
        const monthlySpendSummary = getMonthsAndYearsTransformedMonthlySpendSummary(
          data?.default_commit_cloud_monthly_spend
        );

        return (
          <MonthlySpendSummaryContainer
            columnNamesToPin={[]}
            monthlySpendSummary={monthlySpendSummary}
          />
        );
      }}
    </DataFetcherContainer>
  );
};

export default SPDefaultCommitMonthlySummaryContainerWrapper;
