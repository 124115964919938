// @flow
import React from 'react';

import { Spacer } from './components/presentational/spacing/Spacer';

// General
export const YES = 'YES';
export const NO = 'NO';
export const ALL = 'ALL';
export const ALL_IN_SMALL_CASE = 'all';
export const ENABLE = 'Enable';
export const ENABLED = 'Enabled';
export const DISABLED = 'Disabled';
export const HEADER_SUFFIX_FOR_DOLLAR_COLUMNS = '(in $)';
export const HEADER_SUFFIX_FOR_MARGIN_COLUMNS = '(in %)';
export const TOTAL_COMMITMENT = 'Total Commitment';
export const ESTIMATE_CUSTOMER_VIEW_ENABLED_BACKEND_NAME = 'customer_view_enabled';
export const ESTIMATE_CUSTOMER_VIEW_ENABLED_DISPLAY_NAME = 'Customer View';
export const DEFAULT_CONFLICTING_UPDATE_ERROR_MESSAGE =
  'There has been a conflict in your request! Please check!';

export const CONFLICTING_UPDATE_ERROR_MESSAGE = (
  <>
    <p>
      The Estimate has changed since you last retrieved it, please <Spacer x={2} />
      <mark>
        <strong>refresh</strong>
      </mark>
      <Spacer x={2} /> your browser to see the latest version and try again!
    </p>
  </>
);

export const DEFAULT_FE_ERROR_MESSAGE =
  'An error occurred, please contact #cloud-commitment-estimator channel!';

export const ERROR_MESSAGES_MAPPING_FOR_409 = {
  'Estimate Version Is Outdated': CONFLICTING_UPDATE_ERROR_MESSAGE,
};

// FieldInputGrid Related
export const IS_ROW_OPENED_FOR_EDITING = 'isRowOpenedForEditing';
export const IS_ROW_FROZEN = 'isRowFrozen';
export const DB_ROW_ID = 'dbRowId';
export const ROW_ID = 'id';
export const DEFAULT_VALUE_FOR_DB_ROW_ID = -1;
export const DEFAULT_VALUE_FOR_DB_ROW_ID_STR = `${DEFAULT_VALUE_FOR_DB_ROW_ID}`;

// Home Page Related
export const HIERARCHY_LEVELS_TO_ICON_COLORS_MAP = new Map([
  ['Account', 'blue'],
  ['Organization', 'teal'],
  ['Opportunity', 'grey'],
]);
export const DEFAULT_NUMBER_OF_ACCOUNTS_TO_SHOW_ON_HOME_PAGE = 10;
export const MAXIMUM_NUMBER_OF_ACCOUNTS_PER_PAGE_ON_HOME_PAGE = 10;
export const LIMIT_INDICATOR_FOR_FETCH_ALL_ON_HOME_PAGE = -1;

// Estimate Details Related
export const ESTIMATE_NAME_DISPLAY_NAME = 'Estimate Name';
export const ESTIMATE_NAME_BACKEND_NAME = 'name';
export const SFDC_ACCOUNT_ID_DISPLAY_NAME = 'Account ID';
export const SFDC_ACCOUNT_ID_BACKEND_NAME = 'salesforce_account_id';
export const SFDC_ACCOUNT_NAME_DISPLAY_NAME = 'Account Name';
export const SFDC_ACCOUNT_NAME_BACKEND_NAME = 'salesforce_account_name';
export const SFDC_ORGANIZATION_ID_DISPLAY_NAME = 'Organization ID';
export const SFDC_ORGANIZATION_ID_BACKEND_NAME = 'org_id';
export const SFDC_ORGANISATION_NAME_DISPLAY_NAME = 'Organization Name';
export const SFDC_ORGANISATION_NAME_BACKEND_NAME = 'org_name';
export const SFDC_OPPORTUNITY_ID_DISPLAY_NAME = 'Opportunity ID';
export const SFDC_OPPORTUNITY_ID_BACKEND_NAME = 'opportunity_id';
export const SFDC_OPPORTUNITY_NAME_DISPLAY_NAME = 'Opportunity Name';
export const SFDC_OPPORTUNITY_NAME_BACKEND_NAME = 'opportunity_name';
export const SFDC_QUOTE_NAME_DISPLAY_NAME = 'SFDC Quote Name';
export const SFDC_QUOTE_NAME_BACKEND_NAME = 'quote_name';
export const ESTIMATE_VERSION_BACKEND_NAME = 'estimate_version';

// Estimate Commit Related
export const MARKETPLACE_DISPLAY_NAME = 'Marketplace';
export const MARKETPLACE_BACKEND_NAME = 'marketplace';
export const MARKETPLACE_NONE = 'None';
export const PAYMENT_SCHEDULE_DISPLAY_NAME = 'Payment Schedule';
export const PAYMENT_SCHEDULE_BACKEND_NAME = 'payment_schedule';
export const PAYMENT_SCHEDULE_PREPAID_BACKEND_NAME = 'Prepaid';
export const PAYMENT_SCHEDULE_PREPAID_DISPLAY_NAME = 'Prepaid';
export const PAYMENT_SCHEDULE_ARREARS_BACKEND_NAME = 'Arrears';
export const PAYMENT_SCHEDULE_ARREARS_DISPLAY_NAME = 'Payable in Arrears';
export const RATE_CARD_DISPLAY_NAME = 'Rate Card';
export const RATE_CARD_BACKEND_NAME = 'rate_card';
export const SUPPORT_TIER_DISPLAY_NAME = 'Support Tier';
export const SUPPORT_TIER_BACKEND_NAME = 'support_tier';
export const DEAL_TYPE_DISPLAY_NAME = 'Deal Type';
export const DEAL_TYPE_BACKEND_NAME = 'deal_type';
export const DEAL_TYPE_RENEWAL = 'Renewal';
export const AUG_1_RATE_CARD_VERSION = '8/1';
export const RATE_CARD_VERSION_4_11_2024 = '4/11/2024';
export const GLOBAL_DISCOUNT_APPLIED_DISPLAY_NAME = 'Global Discount Applied';
export const GLOBAL_DISCOUNT = 'Global Discount';
export const GLOBAL_DISCOUNT_APPLIED_BACKEND_NAME = 'global_discount';
export const DEAL_START_DATE_DISPLAY_NAME = 'Deal Start Date';
export const DEAL_START_DATE_BACKEND_NAME = 'deal_start_date';
export const DEAL_END_DATE_DISPLAY_NAME = 'Deal End Date';
export const DEAL_END_DATE_BACKEND_NAME = 'deal_end_date';
export const CUSTOM_DISCOUNTS_ENABLED_BACKEND_NAME = 'custom_discount_enabled';
export const CUSTOM_DISCOUNTS_ENABLED_DISPLAY_NAME = 'Custom Discounts';
export const CREDITS_AND_BURSTS_ENABLED_BACKEND_NAME = 'cnbc_enabled';
export const CREDITS_AND_BURSTS_ENABLED_DISPLAY_NAME = 'Credits and Bursts';

// Stream Governance Related

// Stream Governance Related
export const STREAM_GOVERNANCE_JSON_BACKEND_NAME = 'stream_governance_json';
export const STREAM_GOVERNANCE_CONFIG_ARRAY_BACKEND_NAME = 'stream_governance_config_array';
export const STREAM_GOVERNANCE_DB_ROW_ID_BACKEND_NAME = 'id';
export const STREAM_GOVERNANCE_ENV_BACKEND_NAME = 'environment_name';
export const STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME = 'package';
export const STREAM_GOVERNANCE_CLOUD_PROVIDER_BACKEND_NAME = 'provider_name';
export const STREAM_GOVERNANCE_CLOUD_PROVIDER_LABEL_BACKEND_NAME = 'provider_label';
export const STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_BACKEND_NAME = 'region_name';
export const STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_LABEL_BACKEND_NAME = 'region_label';
export const STREAM_GOVERNANCE_NUMBER_OF_SCHEMAS_BACKEND_NAME = 'schema_count';
export const STREAM_GOVERNANCE_NUMBER_OF_RULES_BACKEND_NAME = 'rules_count';
export const STREAM_GOVERNANCE = 'Stream Governance';
export const STREAM_GOVERNANCE_SUMMARY = 'Stream Governance Summary';
export const STREAM_GOVERNANCE_ESSENTIALS_PACKAGE_NAME = 'ESSENTIALS';
export const STREAM_GOVERNANCE_ADVANCED_PACKAGE_NAME = 'ADVANCED';
export const STREAM_GOVERNANCE_SUMMARY_GRID_NAME = 'Name';
export const STREAM_GOVERNANCE_SUMMARY_GRID_ENABLED = 'Enabled';

// Estimate Summary Related
export const ESTIMATE_SUMMARY = 'Estimate Summary';
export const ESTIMATE_COMMIT_SUMMARY = 'Commit Summary';
export const ESTIMATE_SFDC_FIELDS_SUMMARY = 'SFDC Fields to Populate';
export const ESTIMATE_ANNUAL_SUMMARIES = 'Annual Summaries';
export const TCO_SUMMARY = 'TCO Summary';
export const ESTIMATE_CLUSTER_PRICING_SUMMARY = 'Cluster Pricing Summary';
export const ESTIMATE_CLUSTER_CONFIGURATION_SUMMARY = 'Cluster Configurations';
export const ESTIMATE_STREAM_GOVERNANCE_PER_ENV_SPEND_SUMMARY = 'Stream Governance Spend Summary';
export const ESTIMATE_STREAM_GOVERNANCE_MONTHLY_SPEND_SUMMARY =
  'Stream Governance Monthly Spend Summary';
export const ESTIMATE_CLOUD_MONTHLY_SPEND_SUMMARY = 'Cloud Monthly Spend Summary';
export const ESTIMATE_MONTHLY_SPEND_SUMMARY = 'Monthly Spend Summary';
export const ESTIMATE_FACT_TABLE_SUMMARY = 'Estimate Custom View';

// Custom Discounts Related
export const CUSTOM_DISCOUNTS_JSON_BACKEND_NAME = 'custom_discounts_json';
export const GLOBAL_DISCOUNT_BACKEND_NAME = 'global_discount';
export const CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME = `${CUSTOM_DISCOUNTS_JSON_BACKEND_NAME}.${GLOBAL_DISCOUNT_BACKEND_NAME}`;
export const CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME_ORIGINAL = `${CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME}_original`;
export const GLOBAL_DISCOUNT_IS_FROZEN_BACKEND_NAME = 'global_discount_isFrozen';
export const CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_IS_FROZEN_BACKEND_NAME = `${CUSTOM_DISCOUNTS_JSON_BACKEND_NAME}.${GLOBAL_DISCOUNT_IS_FROZEN_BACKEND_NAME}`;
export const METRICS_LEVEL_DISCOUNTS_CONFIG_ARRAY_BACKEND_NAME = 'metrics_level_discounts_array';
export const METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_ARRAY_BACKEND_NAME =
  'dimensions_config_array';
export const METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME = 'metricName';
export const METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_ID_BACKEND_NAME = 'metricId';
export const METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DISCOUNT_BACKEND_NAME = 'discount';
// eslint-disable-next-line id-length
export const METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DIMENSION_VALUES_BACKEND_NAME =
  'dimensionValues';

export const METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_MLD_ID_BACKEND_NAME = 'metric_discount_id';

// Credits and Bursts Related
export const CREDITS_AND_BURSTS_JSON_BACKEND_NAME = 'credits_and_bursts_json';
export const CREDITS_AND_BURSTS_CONFIG_ARRAY_BACKEND_NAME = 'cnbc_configs';
export const CREDITS_AND_BURSTS_YEAR_NUMBER_BACKEND_NAME = 'year_number';
export const CREDITS_AND_BURSTS_CREDITS_AMOUNT_BACKEND_NAME = 'credit_amount';
export const CREDITS_AND_BURSTS_BURSTS_AMOUNT_BACKEND_NAME = 'burst_amount';
export const CREDITS_AND_BURSTS_MAX_CONFIGURABLE_LIMIT = 1000000000;

// Clusters Related
export const CLUSTER_ID = 'Cluster ID';
export const CLUSTER_ID_BACKEND_NAME = 'id';
export const CLUSTER_NAME = 'Cluster Name';
export const NAME = 'Name';
export const CLUSTER_NAME_BACKEND_NAME = 'name';
export const TYPE = 'Type';
export const CLUSTER_TYPE = 'Cluster Type';
export const CLUSTER_TYPE_BACKEND_NAME = 'cluster_type';
export const CLUSTER_TYPE_LABEL_BACKEND_NAME = 'cluster_type_label';
export const CLUSTER_NETWORKING = 'Networking';
export const CLUSTER_NETWORKING_TYPE = 'Networking Type';
export const CLUSTER_NETWORKING_TYPE_BACKEND_NAME = 'networking_type';
export const CLUSTER_NETWORKING_TYPE_LABEL_BACKEND_NAME = 'networking_type_label';
export const CLUSTER_PROVIDER = 'Provider';
export const CLUSTER_PROVIDER_BACKEND_NAME = 'provider';
export const CLUSTER_PROVIDER_LABEL_BACKEND_NAME = 'provider_label';
export const CLUSTER_REGION = 'Region';
export const CLUSTER_REGION_BACKEND_NAME = 'region';
export const CLUSTER_REGION_LABEL_BACKEND_NAME = 'region_label';
export const CLUSTER_AVAILABILITY = 'Availability';
export const CLUSTER_DEPLOYMENT = 'Deployment';
export const CLUSTER_AVAILABILITY_IN_SMALL_CASE = 'availability';
export const CLUSTER_AVAILABILITY_BACKEND_NAME = 'az_configuration';
export const CLUSTER_AVAILABILITY_LABEL_BACKEND_NAME = 'az_config_label';
export const CLUSTER_ENABLED = 'Enabled';

export const CLUSTER_ENABLED_BACKEND_NAME = 'isEnabled';
export const CLUSTER_PARTITIONS = 'Partitions';
export const CLUSTER_PARTITIONS_BACKEND_NAME = 'partition_count';
export const CLUSTER_EXISTING_STORAGE_DISPLAY_NAME = 'Existing Storage After Replication( GB )';
export const CLUSTER_EXISTING_STORAGE_BACKEND_NAME = 'existing_storage';
export const CLUSTER_RETENTION = 'Retention (days)';
export const CLUSTER_RETENTION_BACKEND_NAME = 'retention_days';
export const CLUSTER_RETENTION_INFINITE = 'Infinite Retention';
export const CLUSTER_RETENTION_INFINITE_BACKEND_NAME = 'retention_infinite';
export const CLUSTER_TOTAL_SPEND_AT_LIST = 'Total Spend (List)';
export const CLUSTER_TOTAL_SPEND_AT_LIST_BACKEND_NAME = 'total_spend';
export const CLUSTER_TOTAL_SPEND_DISCOUNTED = 'Total Spend (Disc)';
export const CLUSTER_TOTAL_SPEND_DISCOUNTED_BACKEND_NAME = 'total_spend_discounted';
export const CLUSTER_FOLLOWER_FETCH = 'Follow-Fetch (available on VPC 2.0 only)';
export const CLUSTER_FOLLOWER_FETCH_BACKEND_NAME = 'is_follower_fetch_enabled';
export const CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG = 'throughput';
export const CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG = 'ksqldb';
export const CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG = 'cluster_linking';
export const CLUSTER_AVAILABLE_CONNECTORS_LIST = 'Available Connectors';
export const CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME = 'connector_name';
export const CONNECTOR_LABEL = 'connector_label';
export const CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST = 'Connector Networking Type';
export const DEFAULT_VALUE_FOR_CONNECTOR_NETWORKING_TYPE = 'connect_public';
export const CONNECTOR_ALLOWED_NETWORKING_TYPES = [
  'connect_public',
  'connect_peering',
  'connect_private_link',
];
export const CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST_BACKEND_NAME =
  'available_connector_networking_type';
export const CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG = 'connectors';
export const CLUSTER_SLA_DISPLAY_NAME = 'SLA';
export const CLUSTER_SLA_BACKEND_NAME = 'sla';
export const CLUSTER_SLA_DISPLAY_COL_MAPPING = 'sla_label';

// Estimate Form Names
export const ESTIMATE_ACCOUNT_DETAILS_FORM = 'Estimate Account Details Form';
export const ESTIMATE_COMMIT_DETAILS_FORM = 'Estimate Commit Details Form';
export const ESTIMATE_TCO_DETAILS_FORM = 'Estimate TCO Details Form';
export const ESTIMATE_CUSTOM_DISCOUNTS_DETAILS_FORM = 'Estimate Custom Discounts Details Form';
export const ESTIMATE_CNBC_DETAILS_FORM = 'Estimate CNBC Details Form';

// Cluster Form Names
export const CLUSTER_DETAILS_FORM = 'CLUSTER_DETAILS_FORM';
export const CLUSTER_KAFKA_FORM = 'CLUSTER_KAFKA_FORM';
export const CLUSTER_KSQLDB_FORM = 'CLUSTER_KSQLDB_FORM';
export const CLUSTER_CONNECTORS_FORM = 'CLUSTER_CONNECTORS_FORM';
export const CLUSTER_CLUSTER_LINKING_FORM = 'CLUSTER_CLUSTER_LINKING_FORM';
export const CLUSTER_TCO_INPUT_FORM = 'CLUSTER_TCO_INPUT_FORM';
export const CLUSTER_DEDICATED_SURVEY_INPUT_FORM = 'CLUSTER_DEDICATED_SURVEY_INPUT_FORM';

// Cluster Types
export const BASIC_CLUSTER_TYPE = 'basic';
export const STANDARD_CLUSTER_TYPE = 'standard';
export const FREIGHT_CLUSTER_TYPE = 'freight';
export const ENTERPRISE_CLUSTER_TYPE = 'enterprise';
export const DEDICATED_CLUSTER_TYPE = 'dedicated';
export const BASIC_V2_CLUSTER_TYPE = 'basic_v2';
export const STANDARD_V2_CLUSTER_TYPE = 'standard_v2';

// Cluster Providers
export const AWS = 'aws';
export const AZURE = 'azure';
export const GCP = 'gcp';

// Cluster Availability
export const SZ = 'sz';
export const MZ = 'mz';

// Cluster Networking Types
export const INTERNET = 'internet';
export const PEERED_VPC = 'peered_vpc';
export const PRIVATE_LINK = 'private_link';
export const TRANSIT_GATEWAY = 'transit_gateway';

// Cluster Partition Limits

export const BASIC_CLUSTER_PARTITION_LIMIT = 4096;
export const STANDARD_CLUSTER_PARTITION_LIMIT = 4096;
export const ENTERPRISE_CLUSTER_PARTITION_LIMIT = 3000 * 10;
export const FREIGHT_CLUSTER_PARTITION_LIMIT = 3000 * 152;
export const DEDICATED_CLUSTER_PARTITION_LIMIT = 100000;

export const CLUSTER_PARTITIONS_LIMITS_MAP = new Map([
  [BASIC_CLUSTER_TYPE, BASIC_CLUSTER_PARTITION_LIMIT],
  [STANDARD_CLUSTER_TYPE, STANDARD_CLUSTER_PARTITION_LIMIT],
  [ENTERPRISE_CLUSTER_TYPE, ENTERPRISE_CLUSTER_PARTITION_LIMIT],
  [FREIGHT_CLUSTER_TYPE, FREIGHT_CLUSTER_PARTITION_LIMIT],
  [DEDICATED_CLUSTER_TYPE, DEDICATED_CLUSTER_PARTITION_LIMIT],
]);

export const MAX_INPUT_VALUE_FOR_ANY_FIELD = 10000000;

export const CLUSTER_WRITE_THROUGHPUT_LIMITS_MAP = new Map([
  [BASIC_CLUSTER_TYPE, 250],
  [STANDARD_CLUSTER_TYPE, 250],
  [ENTERPRISE_CLUSTER_TYPE, 600],
  [FREIGHT_CLUSTER_TYPE, 60 * 152],
  [DEDICATED_CLUSTER_TYPE, MAX_INPUT_VALUE_FOR_ANY_FIELD],
]);

export const CLUSTER_READ_THROUGHPUT_LIMITS_MAP = new Map([
  [BASIC_CLUSTER_TYPE, 750],
  [STANDARD_CLUSTER_TYPE, 750],
  [ENTERPRISE_CLUSTER_TYPE, 1800],
  [FREIGHT_CLUSTER_TYPE, 180 * 152],
  [DEDICATED_CLUSTER_TYPE, MAX_INPUT_VALUE_FOR_ANY_FIELD],
]);

export const CLUSTER_CONNECTED_CLIENTS_COUNT_LIMITS_MAP = new Map([
  [ENTERPRISE_CLUSTER_TYPE, 4500 * 10],
  [FREIGHT_CLUSTER_TYPE, 9000 * 152],
  [DEDICATED_CLUSTER_TYPE, MAX_INPUT_VALUE_FOR_ANY_FIELD],
]);

export const CLUSTER_CLIENT_CONNECTION_ATTEMPTS_LIMITS_MAP = new Map([
  [ENTERPRISE_CLUSTER_TYPE, 250 * 10],
  [FREIGHT_CLUSTER_TYPE, 250 * 152],
  [DEDICATED_CLUSTER_TYPE, MAX_INPUT_VALUE_FOR_ANY_FIELD],
]);

export const CLUSTER_CLIENT_REQUESTS_LIMITS_MAP = new Map([
  [ENTERPRISE_CLUSTER_TYPE, 7500 * 10],
  [FREIGHT_CLUSTER_TYPE, 15000 * 152],
  [DEDICATED_CLUSTER_TYPE, MAX_INPUT_VALUE_FOR_ANY_FIELD],
]);

// Theming
export const DEFAULT_BACKGROUND_COLOR_FOR_LIGHT_THEME = '#FFFFFF';
export const DEFAULT_BACKGROUND_COLOR_FOR_DARK_THEME = '#111517';
export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';

// Flink Pool Related
export const FLINK_POOL_DETAILS_FORM = 'FLINK_POOL_DETAILS_FORM';
export const FLINK_POOL_MONTHLY_INPUTS_FORM = 'FLINK_POOL_MONTHLY_INPUTS_FORM';
export const FLINK_POOL_ID = 'Flink Pool ID';
export const FLINK_POOL_ID_BACKEND_NAME = 'id';
export const FLINK_POOL_NAME = 'Pool Name';
export const FLINK_POOL_NAME_BACKEND_NAME = 'name';
export const FLINK_POOL_ENABLED = 'Enabled';
export const FLINK_POOL_ENABLED_BACKEND_NAME = 'is_enabled';
export const FLINK_POOL_ENABLED_BACKEND_NAME_FOR_SUMMARY = 'isEnabled';
export const FLINK_POOL_PROVIDER = 'Provider';
export const FLINK_POOL_PROVIDER_BACKEND_NAME = 'provider';
export const FLINK_POOL_PROVIDER_LABEL_BACKEND_NAME = 'provider_label';
export const FLINK_POOL_REGION = 'Region';
export const FLINK_POOL_REGION_BACKEND_NAME = 'region';
export const FLINK_POOL_REGION_LABEL_BACKEND_NAME = 'region_label';
export const FLINK_POOL_TOTAL_SPEND_AT_LIST = 'Total Spend (List)';
export const FLINK_POOL_TOTAL_SPEND_AT_LIST_BACKEND_NAME = 'total_spend';
export const FLINK_POOL_TOTAL_SPEND_DISCOUNTED = 'Total Spend (Disc)';
export const FLINK_POOL_TOTAL_SPEND_DISCOUNTED_BACKEND_NAME = 'total_spend_discounted';
export const FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG = 'flink_pool_monthly_inputs';
export const FLINK_POOL_PRICING_SUMMARY = 'Flink Pool Pricing Summary';
export const FLINK_POOL_CONFIGURATION_SUMMARY = 'Flink Pool Configuration';

// TCO Details

export const ESTIMATE_LEVEL_TCO_INPUTS_SECTION_HEADER = 'TCO Details';
export const TCO_CSP_DISCOUNT_APPLIED_DISPLAY_NAME = 'CSP Discount Applied';
export const TCO_CSP_DISCOUNT_APPLIED_BACKEND_FIELD_NAME = 'csp_discount';
export const TCO_COST_PER_BUSINESS_DOWNTIME_HOUR_DISPLAY_NAME = 'Cost Per Business Downtime Hour';
export const TCO_COST_PER_BUSINESS_DOWNTIME_HOUR_BACKEND_FIELD_NAME = 'downtime_hourly_cost';
export const TCO_OVER_PROVISION_PERCENTAGE_DISPLAY_NAME = 'Over Provision Percentage';
export const TCO_OVER_PROVISION_PERCENTAGE_BACKEND_FIELD_NAME = 'overprovision_percent';
export const TCO_STORAGE_UTILIZATION_BUFFER_DISPLAY_NAME = 'Storage Utilisation Buffer';
export const TCO_STORAGE_UTILIZATION_BUFFER_BACKEND_FIELD_NAME = 'storage_utilization_buffer';
export const TCO_REGION_DISPLAY_NAME = 'Region';
export const TCO_REGION_BACKEND_FIELD_NAME = 'region';
export const TCO_AVAILABLE_REGIONS = 'TCO_AVAILABLE_REGIONS';
export const TCO_INPUTS_JSON_BACKEND_NAME = 'tco_inputs';
export const TCO_CLUSTER_MISSION_CRITICAL_DISPLAY_NAME = 'Mission Critical';
export const TCO_CLUSTER_MISSION_CRITICAL_BACKEND_FIELD_NAME = 'mission_critical';
export const TCO_CLUSTER_FOLLOWER_FETCH_DISPLAY_NAME = 'Fetch From Follower';
export const TCO_CLUSTER_FOLLOWER_FETCH_BACKEND_FIELD_NAME = 'fetch_from_follower';
export const TCO_CLUSTER_TIERED_STORAGE_DISPLAY_NAME = 'Tiered Storage';
export const TCO_CLUSTER_TIERED_STORAGE_BACKEND_FIELD_NAME = 'tiered_storage';
export const TCO_CLUSTER_LOCAL_RETENTION_DISPLAY_NAME = 'Local Retention ( hours )';
export const TCO_CLUSTER_LOCAL_RETENTION_BACKEND_FIELD_NAME = 'local_retention';
export const TCO_VENDOR_MSK = 'MSK';
export const TCO_VENDOR_OSK = 'OSK';
export const TCO_VENDOR_CONFLUENT = 'CONFLUENT';
export const TCO_AVAILABLE_PROVIDERS_LIST = [TCO_VENDOR_OSK, TCO_VENDOR_MSK, TCO_VENDOR_CONFLUENT];
export const TCO_MSK_COMPUTE = 'MSK Compute';
export const TCO_MSK_NETWORK = 'MSK Network';
export const TCO_MSK_STORAGE = 'MSK Storage';
export const TCO_OSK_COMPUTE = 'OSK Compute';
export const TCO_OSK_NETWORK = 'OSK Network';
export const TCO_OSK_STORAGE = 'OSK Storage';
export const TCO_INFRASTRUCTURE = 'Infrastructure';
export const TCO_OPERATING_EXPENSES = 'Operating Expenses';
export const TCO_OPERATIONS = 'Operations';
export const TCO_SUPPORT = 'Support';
export const TCO_RISK = 'Risk';

// Stop Selling Dedicated Related

export const DEDICATED_SURVEY_INPUTS_JSON_BACKEND_FIELD_NAME_FOR_UPDATE = 'survey_inputs';
export const DEDICATED_SURVEY_INPUTS_RENEWAL_DISPLAY_NAME = 'Renewal';
export const DEDICATED_SURVEY_INPUTS_RENEWAL_BACKEND_FIELD_NAME = 'renewal';
export const DEDICATED_SURVEY_INPUTS_THEATRE_DISPLAY_NAME = 'Theatre';
export const DEDICATED_SURVEY_INPUTS_THEATRE_BACKEND_FIELD_NAME = 'theatre';
export const DEDICATED_SURVEY_INPUTS_INDUSTRY_DISPLAY_NAME = 'Industry';
export const DEDICATED_SURVEY_INPUTS_INDUSTRY_BACKEND_FIELD_NAME = 'industry';
export const DEDICATED_SURVEY_INPUTS_REASON_DISPLAY_NAME =
  'Why is the customer not using Enterprise Clusters?';
export const DEDICATED_SURVEY_INPUTS_REASON_BACKEND_FIELD_NAME = 'reasons';
export const DEDICATED_SURVEY_INPUTS_OTHER_REASON_DISPLAY_NAME = 'Other Reason';
export const DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_FIELD_NAME = 'other';
export const DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_NAME = 'Other';

// Feature Flags
export const IS_SFDC_INTEGRATION_LIVE = true;

export const SG_PARAMS_HACK = {
  sg_configs: {
    sg_packages: [
      {
        region_key: 1,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-1',
        region_label: 'N. Virginia (us-east-1)',
        package: 'Essentials',
      },
      {
        region_key: 1,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-1',
        region_label: 'N. Virginia (us-east-1)',
        package: 'Advanced',
      },
      {
        region_key: 2,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-2',
        region_label: 'Ohio (us-east-2)',
        package: 'Essentials',
      },
      {
        region_key: 2,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-2',
        region_label: 'Ohio (us-east-2)',
        package: 'Advanced',
      },
      {
        region_key: 3,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-west-2',
        region_label: 'Oregon (us-west-2)',
        package: 'Essentials',
      },
      {
        region_key: 3,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-west-2',
        region_label: 'Oregon (us-west-2)',
        package: 'Advanced',
      },
      {
        region_key: 5,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-central-1',
        region_label: 'Canada (ca-central-1)',
        package: 'Essentials',
      },
      {
        region_key: 5,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-central-1',
        region_label: 'Canada (ca-central-1)',
        package: 'Advanced',
      },
      {
        region_key: 6,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-1',
        region_label: 'Ireland (eu-west-1)',
        package: 'Essentials',
      },
      {
        region_key: 6,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-1',
        region_label: 'Ireland (eu-west-1)',
        package: 'Advanced',
      },
      {
        region_key: 7,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-1',
        region_label: 'Frankfurt (eu-central-1)',
        package: 'Essentials',
      },
      {
        region_key: 7,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-1',
        region_label: 'Frankfurt (eu-central-1)',
        package: 'Advanced',
      },
      {
        region_key: 8,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-2',
        region_label: 'Zurich (eu-central-2)',
        package: 'Essentials',
      },
      {
        region_key: 8,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-2',
        region_label: 'Zurich (eu-central-2)',
        package: 'Advanced',
      },
      {
        region_key: 9,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-2',
        region_label: 'London (eu-west-2)',
        package: 'Essentials',
      },
      {
        region_key: 9,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-2',
        region_label: 'London (eu-west-2)',
        package: 'Advanced',
      },
      {
        region_key: 10,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-3',
        region_label: 'Paris (eu-west-3)',
        package: 'Essentials',
      },
      {
        region_key: 10,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-3',
        region_label: 'Paris (eu-west-3)',
        package: 'Advanced',
      },
      {
        region_key: 11,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-north-1',
        region_label: 'Stockholm (eu-north-1)',
        package: 'Essentials',
      },
      {
        region_key: 11,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-north-1',
        region_label: 'Stockholm (eu-north-1)',
        package: 'Advanced',
      },
      {
        region_key: 12,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-1',
        region_label: 'Milan (eu-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 12,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-1',
        region_label: 'Milan (eu-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 13,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-2',
        region_label: 'Spain (eu-south-2)',
        package: 'Essentials',
      },
      {
        region_key: 13,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-2',
        region_label: 'Spain (eu-south-2)',
        package: 'Advanced',
      },
      {
        region_key: 14,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'sa-east-1',
        region_label: 'São Paulo (sa-east-1)',
        package: 'Essentials',
      },
      {
        region_key: 14,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'sa-east-1',
        region_label: 'São Paulo (sa-east-1)',
        package: 'Advanced',
      },
      {
        region_key: 15,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-2',
        region_label: 'Sydney (ap-southeast-2)',
        package: 'Essentials',
      },
      {
        region_key: 15,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-2',
        region_label: 'Sydney (ap-southeast-2)',
        package: 'Advanced',
      },
      {
        region_key: 16,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-1',
        region_label: 'Singapore (ap-southeast-1)',
        package: 'Essentials',
      },
      {
        region_key: 16,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-1',
        region_label: 'Singapore (ap-southeast-1)',
        package: 'Advanced',
      },
      {
        region_key: 17,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-3',
        region_label: 'Jakarta (ap-southeast-3)',
        package: 'Essentials',
      },
      {
        region_key: 17,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-3',
        region_label: 'Jakarta (ap-southeast-3)',
        package: 'Advanced',
      },
      {
        region_key: 18,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-1',
        region_label: 'Mumbai (ap-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 18,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-1',
        region_label: 'Mumbai (ap-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 19,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-2',
        region_label: 'Hyderabad (ap-south-2)',
        package: 'Essentials',
      },
      {
        region_key: 19,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-2',
        region_label: 'Hyderabad (ap-south-2)',
        package: 'Advanced',
      },
      {
        region_key: 20,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-2',
        region_label: 'Seoul (ap-northeast-2)',
        package: 'Essentials',
      },
      {
        region_key: 20,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-2',
        region_label: 'Seoul (ap-northeast-2)',
        package: 'Advanced',
      },
      {
        region_key: 21,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-1',
        region_label: 'Tokyo (ap-northeast-1)',
        package: 'Essentials',
      },
      {
        region_key: 21,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-1',
        region_label: 'Tokyo (ap-northeast-1)',
        package: 'Advanced',
      },
      {
        region_key: 22,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-3',
        region_label: 'Osaka (ap-northeast-3)',
        package: 'Essentials',
      },
      {
        region_key: 22,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-3',
        region_label: 'Osaka (ap-northeast-3)',
        package: 'Advanced',
      },
      {
        region_key: 23,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-east-1',
        region_label: 'Hong Kong (ap-east-1)',
        package: 'Essentials',
      },
      {
        region_key: 23,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-east-1',
        region_label: 'Hong Kong (ap-east-1)',
        package: 'Advanced',
      },
      {
        region_key: 24,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-south-1',
        region_label: 'Bahrain (me-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 24,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-south-1',
        region_label: 'Bahrain (me-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 25,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-central-1',
        region_label: 'UAE (me-central-1)',
        package: 'Essentials',
      },
      {
        region_key: 25,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-central-1',
        region_label: 'UAE (me-central-1)',
        package: 'Advanced',
      },
      {
        region_key: 26,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'af-south-1',
        region_label: 'Cape Town (af-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 26,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'af-south-1',
        region_label: 'Cape Town (af-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 27,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-4',
        region_label: 'Melbourne (ap-southeast-4)',
        package: 'Essentials',
      },
      {
        region_key: 27,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-4',
        region_label: 'Melbourne (ap-southeast-4)',
        package: 'Advanced',
      },
      {
        region_key: 28,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'il-central-1',
        region_label: 'Tel Aviv (il-central1)',
        package: 'Essentials',
      },
      {
        region_key: 28,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'il-central-1',
        region_label: 'Tel Aviv (il-central1)',
        package: 'Advanced',
      },
      {
        region_key: 29,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-west-1',
        region_label: 'Calgary (ca-west-1)',
        package: 'Essentials',
      },
      {
        region_key: 29,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-west-1',
        region_label: 'Calgary (ca-west-1)',
        package: 'Advanced',
      },
      {
        region_key: 30,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-central1',
        region_label: 'Iowa (us-central1)',
        package: 'Essentials',
      },
      {
        region_key: 30,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-central1',
        region_label: 'Iowa (us-central1)',
        package: 'Advanced',
      },
      {
        region_key: 31,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west1',
        region_label: 'Oregon (us-west1)',
        package: 'Essentials',
      },
      {
        region_key: 31,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west1',
        region_label: 'Oregon (us-west1)',
        package: 'Advanced',
      },
      {
        region_key: 32,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east1',
        region_label: 'S. Carolina (us-east1)',
        package: 'Essentials',
      },
      {
        region_key: 32,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east1',
        region_label: 'S. Carolina (us-east1)',
        package: 'Advanced',
      },
      {
        region_key: 33,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east4',
        region_label: 'N. Virginia (us-east4)',
        package: 'Essentials',
      },
      {
        region_key: 33,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east4',
        region_label: 'N. Virginia (us-east4)',
        package: 'Advanced',
      },
      {
        region_key: 34,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west2',
        region_label: 'Los Angeles (us-west2)',
        package: 'Essentials',
      },
      {
        region_key: 34,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west2',
        region_label: 'Los Angeles (us-west2)',
        package: 'Advanced',
      },
      {
        region_key: 35,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west4',
        region_label: 'Las Vegas (us-west4)',
        package: 'Essentials',
      },
      {
        region_key: 35,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west4',
        region_label: 'Las Vegas (us-west4)',
        package: 'Advanced',
      },
      {
        region_key: 36,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast1',
        region_label: 'Montreal (northamerica-northeast1)',
        package: 'Essentials',
      },
      {
        region_key: 36,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast1',
        region_label: 'Montreal (northamerica-northeast1)',
        package: 'Advanced',
      },
      {
        region_key: 37,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast2',
        region_label: 'Toronto (northamerica-northeast2)',
        package: 'Essentials',
      },
      {
        region_key: 37,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast2',
        region_label: 'Toronto (northamerica-northeast2)',
        package: 'Advanced',
      },
      {
        region_key: 38,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-east1',
        region_label: 'Sao Paulo (southamerica-east1)',
        package: 'Essentials',
      },
      {
        region_key: 38,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-east1',
        region_label: 'Sao Paulo (southamerica-east1)',
        package: 'Advanced',
      },
      {
        region_key: 39,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-west1',
        region_label: 'Santiago (southamerica-west1)',
        package: 'Essentials',
      },
      {
        region_key: 39,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-west1',
        region_label: 'Santiago (southamerica-west1)',
        package: 'Advanced',
      },
      {
        region_key: 40,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west1',
        region_label: 'Belgium (europe-west1)',
        package: 'Essentials',
      },
      {
        region_key: 40,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west1',
        region_label: 'Belgium (europe-west1)',
        package: 'Advanced',
      },
      {
        region_key: 41,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west2',
        region_label: 'London (europe-west2)',
        package: 'Essentials',
      },
      {
        region_key: 41,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west2',
        region_label: 'London (europe-west2)',
        package: 'Advanced',
      },
      {
        region_key: 42,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west3',
        region_label: 'Frankfurt (europe-west3)',
        package: 'Essentials',
      },
      {
        region_key: 42,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west3',
        region_label: 'Frankfurt (europe-west3)',
        package: 'Advanced',
      },
      {
        region_key: 43,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west4',
        region_label: 'Netherlands (europe-west4)',
        package: 'Essentials',
      },
      {
        region_key: 43,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west4',
        region_label: 'Netherlands (europe-west4)',
        package: 'Advanced',
      },
      {
        region_key: 44,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west6',
        region_label: 'Zurich (europe-west6)',
        package: 'Essentials',
      },
      {
        region_key: 44,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west6',
        region_label: 'Zurich (europe-west6)',
        package: 'Advanced',
      },
      {
        region_key: 45,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west8',
        region_label: 'Milan (europe-west8)',
        package: 'Essentials',
      },
      {
        region_key: 45,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west8',
        region_label: 'Milan (europe-west8)',
        package: 'Advanced',
      },
      {
        region_key: 46,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-north1',
        region_label: 'Finland (europe-north1)',
        package: 'Essentials',
      },
      {
        region_key: 46,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-north1',
        region_label: 'Finland (europe-north1)',
        package: 'Advanced',
      },
      {
        region_key: 47,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-central2',
        region_label: 'Warsaw (europe-central2)',
        package: 'Essentials',
      },
      {
        region_key: 47,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-central2',
        region_label: 'Warsaw (europe-central2)',
        package: 'Advanced',
      },
      {
        region_key: 48,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-southwest1',
        region_label: 'Madrid (europe-southwest1)',
        package: 'Essentials',
      },
      {
        region_key: 48,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-southwest1',
        region_label: 'Madrid (europe-southwest1)',
        package: 'Advanced',
      },
      {
        region_key: 49,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south1',
        region_label: 'Mumbai (asia-south1)',
        package: 'Essentials',
      },
      {
        region_key: 49,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south1',
        region_label: 'Mumbai (asia-south1)',
        package: 'Advanced',
      },
      {
        region_key: 50,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south2',
        region_label: 'Delhi (asia-south2)',
        package: 'Essentials',
      },
      {
        region_key: 50,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south2',
        region_label: 'Delhi (asia-south2)',
        package: 'Advanced',
      },
      {
        region_key: 51,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east1',
        region_label: 'Taiwan (asia-east1)',
        package: 'Essentials',
      },
      {
        region_key: 51,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east1',
        region_label: 'Taiwan (asia-east1)',
        package: 'Advanced',
      },
      {
        region_key: 52,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east2',
        region_label: 'Hong Kong (asia-east2)',
        package: 'Essentials',
      },
      {
        region_key: 52,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east2',
        region_label: 'Hong Kong (asia-east2)',
        package: 'Advanced',
      },
      {
        region_key: 53,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast1',
        region_label: 'Tokyo (asia-northeast1)',
        package: 'Essentials',
      },
      {
        region_key: 53,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast1',
        region_label: 'Tokyo (asia-northeast1)',
        package: 'Advanced',
      },
      {
        region_key: 54,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast2',
        region_label: 'Osaka (asia-northeast2)',
        package: 'Essentials',
      },
      {
        region_key: 54,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast2',
        region_label: 'Osaka (asia-northeast2)',
        package: 'Advanced',
      },
      {
        region_key: 55,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast3',
        region_label: 'Seoul (asia-northeast3)',
        package: 'Essentials',
      },
      {
        region_key: 55,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast3',
        region_label: 'Seoul (asia-northeast3)',
        package: 'Advanced',
      },
      {
        region_key: 56,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast1',
        region_label: 'Singapore (asia-southeast1)',
        package: 'Essentials',
      },
      {
        region_key: 56,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast1',
        region_label: 'Singapore (asia-southeast1)',
        package: 'Advanced',
      },
      {
        region_key: 57,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast2',
        region_label: 'Jakarta (asia-southeast2)',
        package: 'Essentials',
      },
      {
        region_key: 57,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast2',
        region_label: 'Jakarta (asia-southeast2)',
        package: 'Advanced',
      },
      {
        region_key: 58,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast1',
        region_label: 'Sydney (australia-southeast1)',
        package: 'Essentials',
      },
      {
        region_key: 58,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast1',
        region_label: 'Sydney (australia-southeast1)',
        package: 'Advanced',
      },
      {
        region_key: 59,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast2',
        region_label: 'Melbourne(australia-southeast2)',
        package: 'Essentials',
      },
      {
        region_key: 59,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast2',
        region_label: 'Melbourne(australia-southeast2)',
        package: 'Advanced',
      },
      {
        region_key: 60,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-west1',
        region_label: 'Tel Aviv (me-west1)',
        package: 'Essentials',
      },
      {
        region_key: 60,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-west1',
        region_label: 'Tel Aviv (me-west1)',
        package: 'Advanced',
      },
      {
        region_key: 61,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west12',
        region_label: 'Turin (europe-west12)',
        package: 'Essentials',
      },
      {
        region_key: 61,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west12',
        region_label: 'Turin (europe-west12)',
        package: 'Advanced',
      },
      {
        region_key: 62,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west9',
        region_label: 'Paris (europe-west9)',
        package: 'Essentials',
      },
      {
        region_key: 62,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west9',
        region_label: 'Paris (europe-west9)',
        package: 'Advanced',
      },
      {
        region_key: 63,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central1',
        region_label: 'Doha (me-central1)',
        package: 'Essentials',
      },
      {
        region_key: 63,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central1',
        region_label: 'Doha (me-central1)',
        package: 'Advanced',
      },
      {
        region_key: 64,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central2',
        region_label: 'Dammam (me-central2)',
        package: 'Essentials',
      },
      {
        region_key: 64,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central2',
        region_label: 'Dammam (me-central2)',
        package: 'Advanced',
      },
      {
        region_key: 65,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus',
        region_label: 'Virginia (eastus)',
        package: 'Essentials',
      },
      {
        region_key: 65,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus',
        region_label: 'Virginia (eastus)',
        package: 'Advanced',
      },
      {
        region_key: 66,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus2',
        region_label: 'Virginia (eastus2)',
        package: 'Essentials',
      },
      {
        region_key: 66,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus2',
        region_label: 'Virginia (eastus2)',
        package: 'Advanced',
      },
      {
        region_key: 67,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus2',
        region_label: 'Washington (westus2)',
        package: 'Essentials',
      },
      {
        region_key: 67,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus2',
        region_label: 'Washington (westus2)',
        package: 'Advanced',
      },
      {
        region_key: 68,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus3',
        region_label: 'Phoenix (westus3)',
        package: 'Essentials',
      },
      {
        region_key: 68,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus3',
        region_label: 'Phoenix (westus3)',
        package: 'Advanced',
      },
      {
        region_key: 69,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralus',
        region_label: 'Iowa (centralus)',
        package: 'Essentials',
      },
      {
        region_key: 69,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralus',
        region_label: 'Iowa (centralus)',
        package: 'Advanced',
      },
      {
        region_key: 70,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southcentralus',
        region_label: 'Texas (southcentralus)',
        package: 'Essentials',
      },
      {
        region_key: 70,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southcentralus',
        region_label: 'Texas (southcentralus)',
        package: 'Advanced',
      },
      {
        region_key: 71,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'canadacentral',
        region_label: 'Toronto (canadacentral)',
        package: 'Essentials',
      },
      {
        region_key: 71,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'canadacentral',
        region_label: 'Toronto (canadacentral)',
        package: 'Advanced',
      },
      {
        region_key: 72,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'brazilsouth',
        region_label: 'Sao Paulo state (brazilsouth)',
        package: 'Essentials',
      },
      {
        region_key: 72,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'brazilsouth',
        region_label: 'Sao Paulo state (brazilsouth)',
        package: 'Advanced',
      },
      {
        region_key: 73,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'northeurope',
        region_label: 'Ireland (northeurope)',
        package: 'Essentials',
      },
      {
        region_key: 73,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'northeurope',
        region_label: 'Ireland (northeurope)',
        package: 'Advanced',
      },
      {
        region_key: 74,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westeurope',
        region_label: 'Netherlands (westeurope)',
        package: 'Essentials',
      },
      {
        region_key: 74,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westeurope',
        region_label: 'Netherlands (westeurope)',
        package: 'Advanced',
      },
      {
        region_key: 75,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'francecentral',
        region_label: 'Paris (francecentral)',
        package: 'Essentials',
      },
      {
        region_key: 75,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'francecentral',
        region_label: 'Paris (francecentral)',
        package: 'Advanced',
      },
      {
        region_key: 76,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'germanywestcentral',
        region_label: 'Frankfurt (germanywestcentral)',
        package: 'Essentials',
      },
      {
        region_key: 76,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'germanywestcentral',
        region_label: 'Frankfurt (germanywestcentral)',
        package: 'Advanced',
      },
      {
        region_key: 77,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uksouth',
        region_label: 'London (uksouth)',
        package: 'Essentials',
      },
      {
        region_key: 77,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uksouth',
        region_label: 'London (uksouth)',
        package: 'Advanced',
      },
      {
        region_key: 78,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'swedencentral',
        region_label: 'Gävle (swedencentral)',
        package: 'Essentials',
      },
      {
        region_key: 78,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'swedencentral',
        region_label: 'Gävle (swedencentral)',
        package: 'Advanced',
      },
      {
        region_key: 79,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'norwayeast',
        region_label: 'Oslo (norwayeast)',
        package: 'Essentials',
      },
      {
        region_key: 79,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'norwayeast',
        region_label: 'Oslo (norwayeast)',
        package: 'Advanced',
      },
      {
        region_key: 80,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'switzerlandnorth',
        region_label: 'Zurich (switzerlandnorth)',
        package: 'Essentials',
      },
      {
        region_key: 80,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'switzerlandnorth',
        region_label: 'Zurich (switzerlandnorth)',
        package: 'Advanced',
      },
      {
        region_key: 81,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southafricanorth',
        region_label: 'Johannesburg (southafricanorth)',
        package: 'Essentials',
      },
      {
        region_key: 81,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southafricanorth',
        region_label: 'Johannesburg (southafricanorth)',
        package: 'Advanced',
      },
      {
        region_key: 82,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uaenorth',
        region_label: 'Dubai (uaenorth)',
        package: 'Essentials',
      },
      {
        region_key: 82,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uaenorth',
        region_label: 'Dubai (uaenorth)',
        package: 'Advanced',
      },
      {
        region_key: 83,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'qatarcentral',
        region_label: 'Qatar (qatarcentral)',
        package: 'Essentials',
      },
      {
        region_key: 83,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'qatarcentral',
        region_label: 'Qatar (qatarcentral)',
        package: 'Advanced',
      },
      {
        region_key: 84,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southeastasia',
        region_label: 'Singapore (southeastasia)',
        package: 'Essentials',
      },
      {
        region_key: 84,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southeastasia',
        region_label: 'Singapore (southeastasia)',
        package: 'Advanced',
      },
      {
        region_key: 85,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralindia',
        region_label: 'Pune (centralindia)',
        package: 'Essentials',
      },
      {
        region_key: 85,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralindia',
        region_label: 'Pune (centralindia)',
        package: 'Advanced',
      },
      {
        region_key: 86,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'koreacentral',
        region_label: 'Seoul (koreacentral)',
        package: 'Essentials',
      },
      {
        region_key: 86,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'koreacentral',
        region_label: 'Seoul (koreacentral)',
        package: 'Advanced',
      },
      {
        region_key: 87,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastasia',
        region_label: 'Hong Kong (eastasia)',
        package: 'Essentials',
      },
      {
        region_key: 87,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastasia',
        region_label: 'Hong Kong (eastasia)',
        package: 'Advanced',
      },
      {
        region_key: 88,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'japaneast',
        region_label: 'Tokyo (japaneast)',
        package: 'Essentials',
      },
      {
        region_key: 88,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'japaneast',
        region_label: 'Tokyo (japaneast)',
        package: 'Advanced',
      },
      {
        region_key: 89,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'australiaeast',
        region_label: 'New South Wales (australiaeast)',
        package: 'Essentials',
      },
      {
        region_key: 89,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'australiaeast',
        region_label: 'New South Wales (australiaeast)',
        package: 'Advanced',
      },
      {
        region_key: 90,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-1',
        region_label: 'N. Virginia (us-east-1)',
        package: 'Essentials',
      },
      {
        region_key: 90,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-1',
        region_label: 'N. Virginia (us-east-1)',
        package: 'Advanced',
      },
      {
        region_key: 91,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-2',
        region_label: 'Ohio (us-east-2)',
        package: 'Essentials',
      },
      {
        region_key: 91,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-2',
        region_label: 'Ohio (us-east-2)',
        package: 'Advanced',
      },
      {
        region_key: 92,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-west-2',
        region_label: 'Oregon (us-west-2)',
        package: 'Essentials',
      },
      {
        region_key: 92,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-west-2',
        region_label: 'Oregon (us-west-2)',
        package: 'Advanced',
      },
      {
        region_key: 94,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-central-1',
        region_label: 'Canada (ca-central-1)',
        package: 'Essentials',
      },
      {
        region_key: 94,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-central-1',
        region_label: 'Canada (ca-central-1)',
        package: 'Advanced',
      },
      {
        region_key: 95,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-1',
        region_label: 'Ireland (eu-west-1)',
        package: 'Essentials',
      },
      {
        region_key: 95,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-1',
        region_label: 'Ireland (eu-west-1)',
        package: 'Advanced',
      },
      {
        region_key: 96,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-1',
        region_label: 'Frankfurt (eu-central-1)',
        package: 'Essentials',
      },
      {
        region_key: 96,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-1',
        region_label: 'Frankfurt (eu-central-1)',
        package: 'Advanced',
      },
      {
        region_key: 97,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-2',
        region_label: 'Zurich (eu-central-2)',
        package: 'Essentials',
      },
      {
        region_key: 97,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-2',
        region_label: 'Zurich (eu-central-2)',
        package: 'Advanced',
      },
      {
        region_key: 98,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-2',
        region_label: 'London (eu-west-2)',
        package: 'Essentials',
      },
      {
        region_key: 98,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-2',
        region_label: 'London (eu-west-2)',
        package: 'Advanced',
      },
      {
        region_key: 99,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-3',
        region_label: 'Paris (eu-west-3)',
        package: 'Essentials',
      },
      {
        region_key: 99,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-3',
        region_label: 'Paris (eu-west-3)',
        package: 'Advanced',
      },
      {
        region_key: 100,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-north-1',
        region_label: 'Stockholm (eu-north-1)',
        package: 'Essentials',
      },
      {
        region_key: 100,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-north-1',
        region_label: 'Stockholm (eu-north-1)',
        package: 'Advanced',
      },
      {
        region_key: 101,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-1',
        region_label: 'Milan (eu-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 101,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-1',
        region_label: 'Milan (eu-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 102,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-2',
        region_label: 'Spain (eu-south-2)',
        package: 'Essentials',
      },
      {
        region_key: 102,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-2',
        region_label: 'Spain (eu-south-2)',
        package: 'Advanced',
      },
      {
        region_key: 103,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'sa-east-1',
        region_label: 'São Paulo (sa-east-1)',
        package: 'Essentials',
      },
      {
        region_key: 103,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'sa-east-1',
        region_label: 'São Paulo (sa-east-1)',
        package: 'Advanced',
      },
      {
        region_key: 104,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-2',
        region_label: 'Sydney (ap-southeast-2)',
        package: 'Essentials',
      },
      {
        region_key: 104,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-2',
        region_label: 'Sydney (ap-southeast-2)',
        package: 'Advanced',
      },
      {
        region_key: 105,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-1',
        region_label: 'Singapore (ap-southeast-1)',
        package: 'Essentials',
      },
      {
        region_key: 105,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-1',
        region_label: 'Singapore (ap-southeast-1)',
        package: 'Advanced',
      },
      {
        region_key: 106,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-3',
        region_label: 'Jakarta (ap-southeast-3)',
        package: 'Essentials',
      },
      {
        region_key: 106,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-3',
        region_label: 'Jakarta (ap-southeast-3)',
        package: 'Advanced',
      },
      {
        region_key: 107,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-1',
        region_label: 'Mumbai (ap-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 107,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-1',
        region_label: 'Mumbai (ap-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 108,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-2',
        region_label: 'Hyderabad (ap-south-2)',
        package: 'Essentials',
      },
      {
        region_key: 108,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-2',
        region_label: 'Hyderabad (ap-south-2)',
        package: 'Advanced',
      },
      {
        region_key: 109,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-2',
        region_label: 'Seoul (ap-northeast-2)',
        package: 'Essentials',
      },
      {
        region_key: 109,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-2',
        region_label: 'Seoul (ap-northeast-2)',
        package: 'Advanced',
      },
      {
        region_key: 110,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-1',
        region_label: 'Tokyo (ap-northeast-1)',
        package: 'Essentials',
      },
      {
        region_key: 110,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-1',
        region_label: 'Tokyo (ap-northeast-1)',
        package: 'Advanced',
      },
      {
        region_key: 111,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-3',
        region_label: 'Osaka (ap-northeast-3)',
        package: 'Essentials',
      },
      {
        region_key: 111,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-3',
        region_label: 'Osaka (ap-northeast-3)',
        package: 'Advanced',
      },
      {
        region_key: 112,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-east-1',
        region_label: 'Hong Kong (ap-east-1)',
        package: 'Essentials',
      },
      {
        region_key: 112,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-east-1',
        region_label: 'Hong Kong (ap-east-1)',
        package: 'Advanced',
      },
      {
        region_key: 113,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-south-1',
        region_label: 'Bahrain (me-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 113,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-south-1',
        region_label: 'Bahrain (me-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 114,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-central-1',
        region_label: 'UAE (me-central-1)',
        package: 'Essentials',
      },
      {
        region_key: 114,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-central-1',
        region_label: 'UAE (me-central-1)',
        package: 'Advanced',
      },
      {
        region_key: 115,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'af-south-1',
        region_label: 'Cape Town (af-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 115,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'af-south-1',
        region_label: 'Cape Town (af-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 116,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-4',
        region_label: 'Melbourne (ap-southeast-4)',
        package: 'Essentials',
      },
      {
        region_key: 116,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-4',
        region_label: 'Melbourne (ap-southeast-4)',
        package: 'Advanced',
      },
      {
        region_key: 117,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'il-central-1',
        region_label: 'Tel Aviv (il-central1)',
        package: 'Essentials',
      },
      {
        region_key: 117,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'il-central-1',
        region_label: 'Tel Aviv (il-central1)',
        package: 'Advanced',
      },
      {
        region_key: 118,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-west-1',
        region_label: 'Calgary (ca-west-1)',
        package: 'Essentials',
      },
      {
        region_key: 118,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-west-1',
        region_label: 'Calgary (ca-west-1)',
        package: 'Advanced',
      },
      {
        region_key: 119,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-central1',
        region_label: 'Iowa (us-central1)',
        package: 'Essentials',
      },
      {
        region_key: 119,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-central1',
        region_label: 'Iowa (us-central1)',
        package: 'Advanced',
      },
      {
        region_key: 120,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west1',
        region_label: 'Oregon (us-west1)',
        package: 'Essentials',
      },
      {
        region_key: 120,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west1',
        region_label: 'Oregon (us-west1)',
        package: 'Advanced',
      },
      {
        region_key: 121,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east1',
        region_label: 'S. Carolina (us-east1)',
        package: 'Essentials',
      },
      {
        region_key: 121,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east1',
        region_label: 'S. Carolina (us-east1)',
        package: 'Advanced',
      },
      {
        region_key: 122,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east4',
        region_label: 'N. Virginia (us-east4)',
        package: 'Essentials',
      },
      {
        region_key: 122,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east4',
        region_label: 'N. Virginia (us-east4)',
        package: 'Advanced',
      },
      {
        region_key: 123,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west2',
        region_label: 'Los Angeles (us-west2)',
        package: 'Essentials',
      },
      {
        region_key: 123,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west2',
        region_label: 'Los Angeles (us-west2)',
        package: 'Advanced',
      },
      {
        region_key: 124,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west4',
        region_label: 'Las Vegas (us-west4)',
        package: 'Essentials',
      },
      {
        region_key: 124,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west4',
        region_label: 'Las Vegas (us-west4)',
        package: 'Advanced',
      },
      {
        region_key: 125,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast1',
        region_label: 'Montreal (northamerica-northeast1)',
        package: 'Essentials',
      },
      {
        region_key: 125,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast1',
        region_label: 'Montreal (northamerica-northeast1)',
        package: 'Advanced',
      },
      {
        region_key: 126,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast2',
        region_label: 'Toronto (northamerica-northeast2)',
        package: 'Essentials',
      },
      {
        region_key: 126,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast2',
        region_label: 'Toronto (northamerica-northeast2)',
        package: 'Advanced',
      },
      {
        region_key: 127,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-east1',
        region_label: 'Sao Paulo (southamerica-east1)',
        package: 'Essentials',
      },
      {
        region_key: 127,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-east1',
        region_label: 'Sao Paulo (southamerica-east1)',
        package: 'Advanced',
      },
      {
        region_key: 128,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-west1',
        region_label: 'Santiago (southamerica-west1)',
        package: 'Essentials',
      },
      {
        region_key: 128,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-west1',
        region_label: 'Santiago (southamerica-west1)',
        package: 'Advanced',
      },
      {
        region_key: 129,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west1',
        region_label: 'Belgium (europe-west1)',
        package: 'Essentials',
      },
      {
        region_key: 129,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west1',
        region_label: 'Belgium (europe-west1)',
        package: 'Advanced',
      },
      {
        region_key: 130,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west2',
        region_label: 'London (europe-west2)',
        package: 'Essentials',
      },
      {
        region_key: 130,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west2',
        region_label: 'London (europe-west2)',
        package: 'Advanced',
      },
      {
        region_key: 131,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west3',
        region_label: 'Frankfurt (europe-west3)',
        package: 'Essentials',
      },
      {
        region_key: 131,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west3',
        region_label: 'Frankfurt (europe-west3)',
        package: 'Advanced',
      },
      {
        region_key: 132,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west4',
        region_label: 'Netherlands (europe-west4)',
        package: 'Essentials',
      },
      {
        region_key: 132,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west4',
        region_label: 'Netherlands (europe-west4)',
        package: 'Advanced',
      },
      {
        region_key: 133,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west6',
        region_label: 'Zurich (europe-west6)',
        package: 'Essentials',
      },
      {
        region_key: 133,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west6',
        region_label: 'Zurich (europe-west6)',
        package: 'Advanced',
      },
      {
        region_key: 134,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west8',
        region_label: 'Milan (europe-west8)',
        package: 'Essentials',
      },
      {
        region_key: 134,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west8',
        region_label: 'Milan (europe-west8)',
        package: 'Advanced',
      },
      {
        region_key: 135,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-north1',
        region_label: 'Finland (europe-north1)',
        package: 'Essentials',
      },
      {
        region_key: 135,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-north1',
        region_label: 'Finland (europe-north1)',
        package: 'Advanced',
      },
      {
        region_key: 136,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-central2',
        region_label: 'Warsaw (europe-central2)',
        package: 'Essentials',
      },
      {
        region_key: 136,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-central2',
        region_label: 'Warsaw (europe-central2)',
        package: 'Advanced',
      },
      {
        region_key: 137,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-southwest1',
        region_label: 'Madrid (europe-southwest1)',
        package: 'Essentials',
      },
      {
        region_key: 137,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-southwest1',
        region_label: 'Madrid (europe-southwest1)',
        package: 'Advanced',
      },
      {
        region_key: 138,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south1',
        region_label: 'Mumbai (asia-south1)',
        package: 'Essentials',
      },
      {
        region_key: 138,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south1',
        region_label: 'Mumbai (asia-south1)',
        package: 'Advanced',
      },
      {
        region_key: 139,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south2',
        region_label: 'Delhi (asia-south2)',
        package: 'Essentials',
      },
      {
        region_key: 139,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south2',
        region_label: 'Delhi (asia-south2)',
        package: 'Advanced',
      },
      {
        region_key: 140,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east1',
        region_label: 'Taiwan (asia-east1)',
        package: 'Essentials',
      },
      {
        region_key: 140,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east1',
        region_label: 'Taiwan (asia-east1)',
        package: 'Advanced',
      },
      {
        region_key: 141,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east2',
        region_label: 'Hong Kong (asia-east2)',
        package: 'Essentials',
      },
      {
        region_key: 141,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east2',
        region_label: 'Hong Kong (asia-east2)',
        package: 'Advanced',
      },
      {
        region_key: 142,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast1',
        region_label: 'Tokyo (asia-northeast1)',
        package: 'Essentials',
      },
      {
        region_key: 142,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast1',
        region_label: 'Tokyo (asia-northeast1)',
        package: 'Advanced',
      },
      {
        region_key: 143,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast2',
        region_label: 'Osaka (asia-northeast2)',
        package: 'Essentials',
      },
      {
        region_key: 143,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast2',
        region_label: 'Osaka (asia-northeast2)',
        package: 'Advanced',
      },
      {
        region_key: 144,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast3',
        region_label: 'Seoul (asia-northeast3)',
        package: 'Essentials',
      },
      {
        region_key: 144,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast3',
        region_label: 'Seoul (asia-northeast3)',
        package: 'Advanced',
      },
      {
        region_key: 145,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast1',
        region_label: 'Singapore (asia-southeast1)',
        package: 'Essentials',
      },
      {
        region_key: 145,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast1',
        region_label: 'Singapore (asia-southeast1)',
        package: 'Advanced',
      },
      {
        region_key: 146,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast2',
        region_label: 'Jakarta (asia-southeast2)',
        package: 'Essentials',
      },
      {
        region_key: 146,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast2',
        region_label: 'Jakarta (asia-southeast2)',
        package: 'Advanced',
      },
      {
        region_key: 147,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast1',
        region_label: 'Sydney (australia-southeast1)',
        package: 'Essentials',
      },
      {
        region_key: 147,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast1',
        region_label: 'Sydney (australia-southeast1)',
        package: 'Advanced',
      },
      {
        region_key: 148,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast2',
        region_label: 'Melbourne(australia-southeast2)',
        package: 'Essentials',
      },
      {
        region_key: 148,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast2',
        region_label: 'Melbourne(australia-southeast2)',
        package: 'Advanced',
      },
      {
        region_key: 149,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-west1',
        region_label: 'Tel Aviv (me-west1)',
        package: 'Essentials',
      },
      {
        region_key: 149,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-west1',
        region_label: 'Tel Aviv (me-west1)',
        package: 'Advanced',
      },
      {
        region_key: 150,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west12',
        region_label: 'Turin (europe-west12)',
        package: 'Essentials',
      },
      {
        region_key: 150,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west12',
        region_label: 'Turin (europe-west12)',
        package: 'Advanced',
      },
      {
        region_key: 151,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west9',
        region_label: 'Paris (europe-west9)',
        package: 'Essentials',
      },
      {
        region_key: 151,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west9',
        region_label: 'Paris (europe-west9)',
        package: 'Advanced',
      },
      {
        region_key: 152,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central1',
        region_label: 'Doha (me-central1)',
        package: 'Essentials',
      },
      {
        region_key: 152,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central1',
        region_label: 'Doha (me-central1)',
        package: 'Advanced',
      },
      {
        region_key: 153,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central2',
        region_label: 'Dammam (me-central2)',
        package: 'Essentials',
      },
      {
        region_key: 153,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central2',
        region_label: 'Dammam (me-central2)',
        package: 'Advanced',
      },
      {
        region_key: 154,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus',
        region_label: 'Virginia (eastus)',
        package: 'Essentials',
      },
      {
        region_key: 154,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus',
        region_label: 'Virginia (eastus)',
        package: 'Advanced',
      },
      {
        region_key: 155,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus2',
        region_label: 'Virginia (eastus2)',
        package: 'Essentials',
      },
      {
        region_key: 155,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus2',
        region_label: 'Virginia (eastus2)',
        package: 'Advanced',
      },
      {
        region_key: 156,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus2',
        region_label: 'Washington (westus2)',
        package: 'Essentials',
      },
      {
        region_key: 156,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus2',
        region_label: 'Washington (westus2)',
        package: 'Advanced',
      },
      {
        region_key: 157,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus3',
        region_label: 'Phoenix (westus3)',
        package: 'Essentials',
      },
      {
        region_key: 157,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus3',
        region_label: 'Phoenix (westus3)',
        package: 'Advanced',
      },
      {
        region_key: 158,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralus',
        region_label: 'Iowa (centralus)',
        package: 'Essentials',
      },
      {
        region_key: 158,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralus',
        region_label: 'Iowa (centralus)',
        package: 'Advanced',
      },
      {
        region_key: 159,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southcentralus',
        region_label: 'Texas (southcentralus)',
        package: 'Essentials',
      },
      {
        region_key: 159,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southcentralus',
        region_label: 'Texas (southcentralus)',
        package: 'Advanced',
      },
      {
        region_key: 160,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'canadacentral',
        region_label: 'Toronto (canadacentral)',
        package: 'Essentials',
      },
      {
        region_key: 160,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'canadacentral',
        region_label: 'Toronto (canadacentral)',
        package: 'Advanced',
      },
      {
        region_key: 161,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'brazilsouth',
        region_label: 'Sao Paulo state (brazilsouth)',
        package: 'Essentials',
      },
      {
        region_key: 161,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'brazilsouth',
        region_label: 'Sao Paulo state (brazilsouth)',
        package: 'Advanced',
      },
      {
        region_key: 162,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'northeurope',
        region_label: 'Ireland (northeurope)',
        package: 'Essentials',
      },
      {
        region_key: 162,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'northeurope',
        region_label: 'Ireland (northeurope)',
        package: 'Advanced',
      },
      {
        region_key: 163,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westeurope',
        region_label: 'Netherlands (westeurope)',
        package: 'Essentials',
      },
      {
        region_key: 163,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westeurope',
        region_label: 'Netherlands (westeurope)',
        package: 'Advanced',
      },
      {
        region_key: 164,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'francecentral',
        region_label: 'Paris (francecentral)',
        package: 'Essentials',
      },
      {
        region_key: 164,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'francecentral',
        region_label: 'Paris (francecentral)',
        package: 'Advanced',
      },
      {
        region_key: 165,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'germanywestcentral',
        region_label: 'Frankfurt (germanywestcentral)',
        package: 'Essentials',
      },
      {
        region_key: 165,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'germanywestcentral',
        region_label: 'Frankfurt (germanywestcentral)',
        package: 'Advanced',
      },
      {
        region_key: 166,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uksouth',
        region_label: 'London (uksouth)',
        package: 'Essentials',
      },
      {
        region_key: 166,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uksouth',
        region_label: 'London (uksouth)',
        package: 'Advanced',
      },
      {
        region_key: 167,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'swedencentral',
        region_label: 'Gävle (swedencentral)',
        package: 'Essentials',
      },
      {
        region_key: 167,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'swedencentral',
        region_label: 'Gävle (swedencentral)',
        package: 'Advanced',
      },
      {
        region_key: 168,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'norwayeast',
        region_label: 'Oslo (norwayeast)',
        package: 'Essentials',
      },
      {
        region_key: 168,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'norwayeast',
        region_label: 'Oslo (norwayeast)',
        package: 'Advanced',
      },
      {
        region_key: 169,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'switzerlandnorth',
        region_label: 'Zurich (switzerlandnorth)',
        package: 'Essentials',
      },
      {
        region_key: 169,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'switzerlandnorth',
        region_label: 'Zurich (switzerlandnorth)',
        package: 'Advanced',
      },
      {
        region_key: 170,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southafricanorth',
        region_label: 'Johannesburg (southafricanorth)',
        package: 'Essentials',
      },
      {
        region_key: 170,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southafricanorth',
        region_label: 'Johannesburg (southafricanorth)',
        package: 'Advanced',
      },
      {
        region_key: 171,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uaenorth',
        region_label: 'Dubai (uaenorth)',
        package: 'Essentials',
      },
      {
        region_key: 171,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uaenorth',
        region_label: 'Dubai (uaenorth)',
        package: 'Advanced',
      },
      {
        region_key: 172,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'qatarcentral',
        region_label: 'Qatar (qatarcentral)',
        package: 'Essentials',
      },
      {
        region_key: 172,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'qatarcentral',
        region_label: 'Qatar (qatarcentral)',
        package: 'Advanced',
      },
      {
        region_key: 173,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southeastasia',
        region_label: 'Singapore (southeastasia)',
        package: 'Essentials',
      },
      {
        region_key: 173,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southeastasia',
        region_label: 'Singapore (southeastasia)',
        package: 'Advanced',
      },
      {
        region_key: 174,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralindia',
        region_label: 'Pune (centralindia)',
        package: 'Essentials',
      },
      {
        region_key: 174,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralindia',
        region_label: 'Pune (centralindia)',
        package: 'Advanced',
      },
      {
        region_key: 175,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'koreacentral',
        region_label: 'Seoul (koreacentral)',
        package: 'Essentials',
      },
      {
        region_key: 175,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'koreacentral',
        region_label: 'Seoul (koreacentral)',
        package: 'Advanced',
      },
      {
        region_key: 176,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastasia',
        region_label: 'Hong Kong (eastasia)',
        package: 'Essentials',
      },
      {
        region_key: 176,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastasia',
        region_label: 'Hong Kong (eastasia)',
        package: 'Advanced',
      },
      {
        region_key: 177,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'japaneast',
        region_label: 'Tokyo (japaneast)',
        package: 'Essentials',
      },
      {
        region_key: 177,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'japaneast',
        region_label: 'Tokyo (japaneast)',
        package: 'Advanced',
      },
      {
        region_key: 178,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'australiaeast',
        region_label: 'New South Wales (australiaeast)',
        package: 'Essentials',
      },
      {
        region_key: 178,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'australiaeast',
        region_label: 'New South Wales (australiaeast)',
        package: 'Advanced',
      },
      {
        region_key: 179,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-1',
        region_label: 'N. Virginia (us-east-1)',
        package: 'Essentials',
      },
      {
        region_key: 179,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-1',
        region_label: 'N. Virginia (us-east-1)',
        package: 'Advanced',
      },
      {
        region_key: 180,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-2',
        region_label: 'Ohio (us-east-2)',
        package: 'Essentials',
      },
      {
        region_key: 180,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-2',
        region_label: 'Ohio (us-east-2)',
        package: 'Advanced',
      },
      {
        region_key: 181,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-west-2',
        region_label: 'Oregon (us-west-2)',
        package: 'Essentials',
      },
      {
        region_key: 181,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-west-2',
        region_label: 'Oregon (us-west-2)',
        package: 'Advanced',
      },
      {
        region_key: 183,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-central-1',
        region_label: 'Canada (ca-central-1)',
        package: 'Essentials',
      },
      {
        region_key: 183,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-central-1',
        region_label: 'Canada (ca-central-1)',
        package: 'Advanced',
      },
      {
        region_key: 184,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-1',
        region_label: 'Ireland (eu-west-1)',
        package: 'Essentials',
      },
      {
        region_key: 184,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-1',
        region_label: 'Ireland (eu-west-1)',
        package: 'Advanced',
      },
      {
        region_key: 185,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-1',
        region_label: 'Frankfurt (eu-central-1)',
        package: 'Essentials',
      },
      {
        region_key: 185,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-1',
        region_label: 'Frankfurt (eu-central-1)',
        package: 'Advanced',
      },
      {
        region_key: 186,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-2',
        region_label: 'Zurich (eu-central-2)',
        package: 'Essentials',
      },
      {
        region_key: 186,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-2',
        region_label: 'Zurich (eu-central-2)',
        package: 'Advanced',
      },
      {
        region_key: 187,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-2',
        region_label: 'London (eu-west-2)',
        package: 'Essentials',
      },
      {
        region_key: 187,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-2',
        region_label: 'London (eu-west-2)',
        package: 'Advanced',
      },
      {
        region_key: 188,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-3',
        region_label: 'Paris (eu-west-3)',
        package: 'Essentials',
      },
      {
        region_key: 188,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-3',
        region_label: 'Paris (eu-west-3)',
        package: 'Advanced',
      },
      {
        region_key: 189,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-north-1',
        region_label: 'Stockholm (eu-north-1)',
        package: 'Essentials',
      },
      {
        region_key: 189,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-north-1',
        region_label: 'Stockholm (eu-north-1)',
        package: 'Advanced',
      },
      {
        region_key: 190,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-1',
        region_label: 'Milan (eu-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 190,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-1',
        region_label: 'Milan (eu-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 191,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-2',
        region_label: 'Spain (eu-south-2)',
        package: 'Essentials',
      },
      {
        region_key: 191,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-2',
        region_label: 'Spain (eu-south-2)',
        package: 'Advanced',
      },
      {
        region_key: 192,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'sa-east-1',
        region_label: 'São Paulo (sa-east-1)',
        package: 'Essentials',
      },
      {
        region_key: 192,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'sa-east-1',
        region_label: 'São Paulo (sa-east-1)',
        package: 'Advanced',
      },
      {
        region_key: 193,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-2',
        region_label: 'Sydney (ap-southeast-2)',
        package: 'Essentials',
      },
      {
        region_key: 193,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-2',
        region_label: 'Sydney (ap-southeast-2)',
        package: 'Advanced',
      },
      {
        region_key: 194,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-1',
        region_label: 'Singapore (ap-southeast-1)',
        package: 'Essentials',
      },
      {
        region_key: 194,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-1',
        region_label: 'Singapore (ap-southeast-1)',
        package: 'Advanced',
      },
      {
        region_key: 195,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-3',
        region_label: 'Jakarta (ap-southeast-3)',
        package: 'Essentials',
      },
      {
        region_key: 195,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-3',
        region_label: 'Jakarta (ap-southeast-3)',
        package: 'Advanced',
      },
      {
        region_key: 196,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-1',
        region_label: 'Mumbai (ap-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 196,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-1',
        region_label: 'Mumbai (ap-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 197,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-2',
        region_label: 'Hyderabad (ap-south-2)',
        package: 'Essentials',
      },
      {
        region_key: 197,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-2',
        region_label: 'Hyderabad (ap-south-2)',
        package: 'Advanced',
      },
      {
        region_key: 198,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-2',
        region_label: 'Seoul (ap-northeast-2)',
        package: 'Essentials',
      },
      {
        region_key: 198,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-2',
        region_label: 'Seoul (ap-northeast-2)',
        package: 'Advanced',
      },
      {
        region_key: 199,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-1',
        region_label: 'Tokyo (ap-northeast-1)',
        package: 'Essentials',
      },
      {
        region_key: 199,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-1',
        region_label: 'Tokyo (ap-northeast-1)',
        package: 'Advanced',
      },
      {
        region_key: 200,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-3',
        region_label: 'Osaka (ap-northeast-3)',
        package: 'Essentials',
      },
      {
        region_key: 200,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-3',
        region_label: 'Osaka (ap-northeast-3)',
        package: 'Advanced',
      },
      {
        region_key: 201,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-east-1',
        region_label: 'Hong Kong (ap-east-1)',
        package: 'Essentials',
      },
      {
        region_key: 201,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-east-1',
        region_label: 'Hong Kong (ap-east-1)',
        package: 'Advanced',
      },
      {
        region_key: 202,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-south-1',
        region_label: 'Bahrain (me-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 202,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-south-1',
        region_label: 'Bahrain (me-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 203,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-central-1',
        region_label: 'UAE (me-central-1)',
        package: 'Essentials',
      },
      {
        region_key: 203,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-central-1',
        region_label: 'UAE (me-central-1)',
        package: 'Advanced',
      },
      {
        region_key: 204,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'af-south-1',
        region_label: 'Cape Town (af-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 204,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'af-south-1',
        region_label: 'Cape Town (af-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 205,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-4',
        region_label: 'Melbourne (ap-southeast-4)',
        package: 'Essentials',
      },
      {
        region_key: 205,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-4',
        region_label: 'Melbourne (ap-southeast-4)',
        package: 'Advanced',
      },
      {
        region_key: 206,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'il-central-1',
        region_label: 'Tel Aviv (il-central1)',
        package: 'Essentials',
      },
      {
        region_key: 206,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'il-central-1',
        region_label: 'Tel Aviv (il-central1)',
        package: 'Advanced',
      },
      {
        region_key: 207,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-west-1',
        region_label: 'Calgary (ca-west-1)',
        package: 'Essentials',
      },
      {
        region_key: 207,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-west-1',
        region_label: 'Calgary (ca-west-1)',
        package: 'Advanced',
      },
      {
        region_key: 208,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-central1',
        region_label: 'Iowa (us-central1)',
        package: 'Essentials',
      },
      {
        region_key: 208,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-central1',
        region_label: 'Iowa (us-central1)',
        package: 'Advanced',
      },
      {
        region_key: 209,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west1',
        region_label: 'Oregon (us-west1)',
        package: 'Essentials',
      },
      {
        region_key: 209,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west1',
        region_label: 'Oregon (us-west1)',
        package: 'Advanced',
      },
      {
        region_key: 210,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east1',
        region_label: 'S. Carolina (us-east1)',
        package: 'Essentials',
      },
      {
        region_key: 210,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east1',
        region_label: 'S. Carolina (us-east1)',
        package: 'Advanced',
      },
      {
        region_key: 211,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east4',
        region_label: 'N. Virginia (us-east4)',
        package: 'Essentials',
      },
      {
        region_key: 211,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east4',
        region_label: 'N. Virginia (us-east4)',
        package: 'Advanced',
      },
      {
        region_key: 212,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west2',
        region_label: 'Los Angeles (us-west2)',
        package: 'Essentials',
      },
      {
        region_key: 212,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west2',
        region_label: 'Los Angeles (us-west2)',
        package: 'Advanced',
      },
      {
        region_key: 213,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west4',
        region_label: 'Las Vegas (us-west4)',
        package: 'Essentials',
      },
      {
        region_key: 213,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west4',
        region_label: 'Las Vegas (us-west4)',
        package: 'Advanced',
      },
      {
        region_key: 214,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast1',
        region_label: 'Montreal (northamerica-northeast1)',
        package: 'Essentials',
      },
      {
        region_key: 214,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast1',
        region_label: 'Montreal (northamerica-northeast1)',
        package: 'Advanced',
      },
      {
        region_key: 215,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast2',
        region_label: 'Toronto (northamerica-northeast2)',
        package: 'Essentials',
      },
      {
        region_key: 215,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast2',
        region_label: 'Toronto (northamerica-northeast2)',
        package: 'Advanced',
      },
      {
        region_key: 216,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-east1',
        region_label: 'Sao Paulo (southamerica-east1)',
        package: 'Essentials',
      },
      {
        region_key: 216,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-east1',
        region_label: 'Sao Paulo (southamerica-east1)',
        package: 'Advanced',
      },
      {
        region_key: 217,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-west1',
        region_label: 'Santiago (southamerica-west1)',
        package: 'Essentials',
      },
      {
        region_key: 217,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-west1',
        region_label: 'Santiago (southamerica-west1)',
        package: 'Advanced',
      },
      {
        region_key: 218,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west1',
        region_label: 'Belgium (europe-west1)',
        package: 'Essentials',
      },
      {
        region_key: 218,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west1',
        region_label: 'Belgium (europe-west1)',
        package: 'Advanced',
      },
      {
        region_key: 219,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west2',
        region_label: 'London (europe-west2)',
        package: 'Essentials',
      },
      {
        region_key: 219,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west2',
        region_label: 'London (europe-west2)',
        package: 'Advanced',
      },
      {
        region_key: 220,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west3',
        region_label: 'Frankfurt (europe-west3)',
        package: 'Essentials',
      },
      {
        region_key: 220,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west3',
        region_label: 'Frankfurt (europe-west3)',
        package: 'Advanced',
      },
      {
        region_key: 221,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west4',
        region_label: 'Netherlands (europe-west4)',
        package: 'Essentials',
      },
      {
        region_key: 221,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west4',
        region_label: 'Netherlands (europe-west4)',
        package: 'Advanced',
      },
      {
        region_key: 222,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west6',
        region_label: 'Zurich (europe-west6)',
        package: 'Essentials',
      },
      {
        region_key: 222,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west6',
        region_label: 'Zurich (europe-west6)',
        package: 'Advanced',
      },
      {
        region_key: 223,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west8',
        region_label: 'Milan (europe-west8)',
        package: 'Essentials',
      },
      {
        region_key: 223,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west8',
        region_label: 'Milan (europe-west8)',
        package: 'Advanced',
      },
      {
        region_key: 224,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-north1',
        region_label: 'Finland (europe-north1)',
        package: 'Essentials',
      },
      {
        region_key: 224,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-north1',
        region_label: 'Finland (europe-north1)',
        package: 'Advanced',
      },
      {
        region_key: 225,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-central2',
        region_label: 'Warsaw (europe-central2)',
        package: 'Essentials',
      },
      {
        region_key: 225,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-central2',
        region_label: 'Warsaw (europe-central2)',
        package: 'Advanced',
      },
      {
        region_key: 226,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-southwest1',
        region_label: 'Madrid (europe-southwest1)',
        package: 'Essentials',
      },
      {
        region_key: 226,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-southwest1',
        region_label: 'Madrid (europe-southwest1)',
        package: 'Advanced',
      },
      {
        region_key: 227,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south1',
        region_label: 'Mumbai (asia-south1)',
        package: 'Essentials',
      },
      {
        region_key: 227,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south1',
        region_label: 'Mumbai (asia-south1)',
        package: 'Advanced',
      },
      {
        region_key: 228,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south2',
        region_label: 'Delhi (asia-south2)',
        package: 'Essentials',
      },
      {
        region_key: 228,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south2',
        region_label: 'Delhi (asia-south2)',
        package: 'Advanced',
      },
      {
        region_key: 229,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east1',
        region_label: 'Taiwan (asia-east1)',
        package: 'Essentials',
      },
      {
        region_key: 229,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east1',
        region_label: 'Taiwan (asia-east1)',
        package: 'Advanced',
      },
      {
        region_key: 230,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east2',
        region_label: 'Hong Kong (asia-east2)',
        package: 'Essentials',
      },
      {
        region_key: 230,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east2',
        region_label: 'Hong Kong (asia-east2)',
        package: 'Advanced',
      },
      {
        region_key: 231,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast1',
        region_label: 'Tokyo (asia-northeast1)',
        package: 'Essentials',
      },
      {
        region_key: 231,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast1',
        region_label: 'Tokyo (asia-northeast1)',
        package: 'Advanced',
      },
      {
        region_key: 232,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast2',
        region_label: 'Osaka (asia-northeast2)',
        package: 'Essentials',
      },
      {
        region_key: 232,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast2',
        region_label: 'Osaka (asia-northeast2)',
        package: 'Advanced',
      },
      {
        region_key: 233,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast3',
        region_label: 'Seoul (asia-northeast3)',
        package: 'Essentials',
      },
      {
        region_key: 233,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast3',
        region_label: 'Seoul (asia-northeast3)',
        package: 'Advanced',
      },
      {
        region_key: 234,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast1',
        region_label: 'Singapore (asia-southeast1)',
        package: 'Essentials',
      },
      {
        region_key: 234,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast1',
        region_label: 'Singapore (asia-southeast1)',
        package: 'Advanced',
      },
      {
        region_key: 235,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast2',
        region_label: 'Jakarta (asia-southeast2)',
        package: 'Essentials',
      },
      {
        region_key: 235,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast2',
        region_label: 'Jakarta (asia-southeast2)',
        package: 'Advanced',
      },
      {
        region_key: 236,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast1',
        region_label: 'Sydney (australia-southeast1)',
        package: 'Essentials',
      },
      {
        region_key: 236,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast1',
        region_label: 'Sydney (australia-southeast1)',
        package: 'Advanced',
      },
      {
        region_key: 237,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast2',
        region_label: 'Melbourne(australia-southeast2)',
        package: 'Essentials',
      },
      {
        region_key: 237,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast2',
        region_label: 'Melbourne(australia-southeast2)',
        package: 'Advanced',
      },
      {
        region_key: 238,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-west1',
        region_label: 'Tel Aviv (me-west1)',
        package: 'Essentials',
      },
      {
        region_key: 238,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-west1',
        region_label: 'Tel Aviv (me-west1)',
        package: 'Advanced',
      },
      {
        region_key: 239,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west12',
        region_label: 'Turin (europe-west12)',
        package: 'Essentials',
      },
      {
        region_key: 239,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west12',
        region_label: 'Turin (europe-west12)',
        package: 'Advanced',
      },
      {
        region_key: 240,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west9',
        region_label: 'Paris (europe-west9)',
        package: 'Essentials',
      },
      {
        region_key: 240,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west9',
        region_label: 'Paris (europe-west9)',
        package: 'Advanced',
      },
      {
        region_key: 241,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central1',
        region_label: 'Doha (me-central1)',
        package: 'Essentials',
      },
      {
        region_key: 241,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central1',
        region_label: 'Doha (me-central1)',
        package: 'Advanced',
      },
      {
        region_key: 242,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central2',
        region_label: 'Dammam (me-central2)',
        package: 'Essentials',
      },
      {
        region_key: 242,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central2',
        region_label: 'Dammam (me-central2)',
        package: 'Advanced',
      },
      {
        region_key: 243,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus',
        region_label: 'Virginia (eastus)',
        package: 'Essentials',
      },
      {
        region_key: 243,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus',
        region_label: 'Virginia (eastus)',
        package: 'Advanced',
      },
      {
        region_key: 244,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus2',
        region_label: 'Virginia (eastus2)',
        package: 'Essentials',
      },
      {
        region_key: 244,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus2',
        region_label: 'Virginia (eastus2)',
        package: 'Advanced',
      },
      {
        region_key: 245,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus2',
        region_label: 'Washington (westus2)',
        package: 'Essentials',
      },
      {
        region_key: 245,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus2',
        region_label: 'Washington (westus2)',
        package: 'Advanced',
      },
      {
        region_key: 246,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus3',
        region_label: 'Phoenix (westus3)',
        package: 'Essentials',
      },
      {
        region_key: 246,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus3',
        region_label: 'Phoenix (westus3)',
        package: 'Advanced',
      },
      {
        region_key: 247,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralus',
        region_label: 'Iowa (centralus)',
        package: 'Essentials',
      },
      {
        region_key: 247,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralus',
        region_label: 'Iowa (centralus)',
        package: 'Advanced',
      },
      {
        region_key: 248,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southcentralus',
        region_label: 'Texas (southcentralus)',
        package: 'Essentials',
      },
      {
        region_key: 248,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southcentralus',
        region_label: 'Texas (southcentralus)',
        package: 'Advanced',
      },
      {
        region_key: 249,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'canadacentral',
        region_label: 'Toronto (canadacentral)',
        package: 'Essentials',
      },
      {
        region_key: 249,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'canadacentral',
        region_label: 'Toronto (canadacentral)',
        package: 'Advanced',
      },
      {
        region_key: 250,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'brazilsouth',
        region_label: 'Sao Paulo state (brazilsouth)',
        package: 'Essentials',
      },
      {
        region_key: 250,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'brazilsouth',
        region_label: 'Sao Paulo state (brazilsouth)',
        package: 'Advanced',
      },
      {
        region_key: 251,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'northeurope',
        region_label: 'Ireland (northeurope)',
        package: 'Essentials',
      },
      {
        region_key: 251,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'northeurope',
        region_label: 'Ireland (northeurope)',
        package: 'Advanced',
      },
      {
        region_key: 252,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westeurope',
        region_label: 'Netherlands (westeurope)',
        package: 'Essentials',
      },
      {
        region_key: 252,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westeurope',
        region_label: 'Netherlands (westeurope)',
        package: 'Advanced',
      },
      {
        region_key: 253,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'francecentral',
        region_label: 'Paris (francecentral)',
        package: 'Essentials',
      },
      {
        region_key: 253,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'francecentral',
        region_label: 'Paris (francecentral)',
        package: 'Advanced',
      },
      {
        region_key: 254,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'germanywestcentral',
        region_label: 'Frankfurt (germanywestcentral)',
        package: 'Essentials',
      },
      {
        region_key: 254,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'germanywestcentral',
        region_label: 'Frankfurt (germanywestcentral)',
        package: 'Advanced',
      },
      {
        region_key: 255,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uksouth',
        region_label: 'London (uksouth)',
        package: 'Essentials',
      },
      {
        region_key: 255,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uksouth',
        region_label: 'London (uksouth)',
        package: 'Advanced',
      },
      {
        region_key: 256,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'swedencentral',
        region_label: 'Gävle (swedencentral)',
        package: 'Essentials',
      },
      {
        region_key: 256,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'swedencentral',
        region_label: 'Gävle (swedencentral)',
        package: 'Advanced',
      },
      {
        region_key: 257,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'norwayeast',
        region_label: 'Oslo (norwayeast)',
        package: 'Essentials',
      },
      {
        region_key: 257,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'norwayeast',
        region_label: 'Oslo (norwayeast)',
        package: 'Advanced',
      },
      {
        region_key: 258,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'switzerlandnorth',
        region_label: 'Zurich (switzerlandnorth)',
        package: 'Essentials',
      },
      {
        region_key: 258,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'switzerlandnorth',
        region_label: 'Zurich (switzerlandnorth)',
        package: 'Advanced',
      },
      {
        region_key: 259,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southafricanorth',
        region_label: 'Johannesburg (southafricanorth)',
        package: 'Essentials',
      },
      {
        region_key: 259,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southafricanorth',
        region_label: 'Johannesburg (southafricanorth)',
        package: 'Advanced',
      },
      {
        region_key: 260,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uaenorth',
        region_label: 'Dubai (uaenorth)',
        package: 'Essentials',
      },
      {
        region_key: 260,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uaenorth',
        region_label: 'Dubai (uaenorth)',
        package: 'Advanced',
      },
      {
        region_key: 261,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'qatarcentral',
        region_label: 'Qatar (qatarcentral)',
        package: 'Essentials',
      },
      {
        region_key: 261,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'qatarcentral',
        region_label: 'Qatar (qatarcentral)',
        package: 'Advanced',
      },
      {
        region_key: 262,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southeastasia',
        region_label: 'Singapore (southeastasia)',
        package: 'Essentials',
      },
      {
        region_key: 262,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southeastasia',
        region_label: 'Singapore (southeastasia)',
        package: 'Advanced',
      },
      {
        region_key: 263,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralindia',
        region_label: 'Pune (centralindia)',
        package: 'Essentials',
      },
      {
        region_key: 263,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralindia',
        region_label: 'Pune (centralindia)',
        package: 'Advanced',
      },
      {
        region_key: 264,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'koreacentral',
        region_label: 'Seoul (koreacentral)',
        package: 'Essentials',
      },
      {
        region_key: 264,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'koreacentral',
        region_label: 'Seoul (koreacentral)',
        package: 'Advanced',
      },
      {
        region_key: 265,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastasia',
        region_label: 'Hong Kong (eastasia)',
        package: 'Essentials',
      },
      {
        region_key: 265,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastasia',
        region_label: 'Hong Kong (eastasia)',
        package: 'Advanced',
      },
      {
        region_key: 266,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'japaneast',
        region_label: 'Tokyo (japaneast)',
        package: 'Essentials',
      },
      {
        region_key: 266,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'japaneast',
        region_label: 'Tokyo (japaneast)',
        package: 'Advanced',
      },
      {
        region_key: 267,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'australiaeast',
        region_label: 'New South Wales (australiaeast)',
        package: 'Essentials',
      },
      {
        region_key: 267,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'australiaeast',
        region_label: 'New South Wales (australiaeast)',
        package: 'Advanced',
      },
      {
        region_key: 268,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-1',
        region_label: 'N. Virginia (us-east-1)',
        package: 'Essentials',
      },
      {
        region_key: 268,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-1',
        region_label: 'N. Virginia (us-east-1)',
        package: 'Advanced',
      },
      {
        region_key: 269,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-2',
        region_label: 'Ohio (us-east-2)',
        package: 'Essentials',
      },
      {
        region_key: 269,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-east-2',
        region_label: 'Ohio (us-east-2)',
        package: 'Advanced',
      },
      {
        region_key: 270,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-west-2',
        region_label: 'Oregon (us-west-2)',
        package: 'Essentials',
      },
      {
        region_key: 270,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'us-west-2',
        region_label: 'Oregon (us-west-2)',
        package: 'Advanced',
      },
      {
        region_key: 272,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-central-1',
        region_label: 'Canada (ca-central-1)',
        package: 'Essentials',
      },
      {
        region_key: 272,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-central-1',
        region_label: 'Canada (ca-central-1)',
        package: 'Advanced',
      },
      {
        region_key: 273,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-1',
        region_label: 'Ireland (eu-west-1)',
        package: 'Essentials',
      },
      {
        region_key: 273,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-1',
        region_label: 'Ireland (eu-west-1)',
        package: 'Advanced',
      },
      {
        region_key: 274,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-1',
        region_label: 'Frankfurt (eu-central-1)',
        package: 'Essentials',
      },
      {
        region_key: 274,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-1',
        region_label: 'Frankfurt (eu-central-1)',
        package: 'Advanced',
      },
      {
        region_key: 275,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-2',
        region_label: 'Zurich (eu-central-2)',
        package: 'Essentials',
      },
      {
        region_key: 275,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-central-2',
        region_label: 'Zurich (eu-central-2)',
        package: 'Advanced',
      },
      {
        region_key: 276,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-2',
        region_label: 'London (eu-west-2)',
        package: 'Essentials',
      },
      {
        region_key: 276,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-2',
        region_label: 'London (eu-west-2)',
        package: 'Advanced',
      },
      {
        region_key: 277,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-3',
        region_label: 'Paris (eu-west-3)',
        package: 'Essentials',
      },
      {
        region_key: 277,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-west-3',
        region_label: 'Paris (eu-west-3)',
        package: 'Advanced',
      },
      {
        region_key: 278,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-north-1',
        region_label: 'Stockholm (eu-north-1)',
        package: 'Essentials',
      },
      {
        region_key: 278,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-north-1',
        region_label: 'Stockholm (eu-north-1)',
        package: 'Advanced',
      },
      {
        region_key: 279,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-1',
        region_label: 'Milan (eu-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 279,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-1',
        region_label: 'Milan (eu-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 280,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-2',
        region_label: 'Spain (eu-south-2)',
        package: 'Essentials',
      },
      {
        region_key: 280,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'eu-south-2',
        region_label: 'Spain (eu-south-2)',
        package: 'Advanced',
      },
      {
        region_key: 281,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'sa-east-1',
        region_label: 'São Paulo (sa-east-1)',
        package: 'Essentials',
      },
      {
        region_key: 281,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'sa-east-1',
        region_label: 'São Paulo (sa-east-1)',
        package: 'Advanced',
      },
      {
        region_key: 282,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-2',
        region_label: 'Sydney (ap-southeast-2)',
        package: 'Essentials',
      },
      {
        region_key: 282,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-2',
        region_label: 'Sydney (ap-southeast-2)',
        package: 'Advanced',
      },
      {
        region_key: 283,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-1',
        region_label: 'Singapore (ap-southeast-1)',
        package: 'Essentials',
      },
      {
        region_key: 283,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-1',
        region_label: 'Singapore (ap-southeast-1)',
        package: 'Advanced',
      },
      {
        region_key: 284,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-3',
        region_label: 'Jakarta (ap-southeast-3)',
        package: 'Essentials',
      },
      {
        region_key: 284,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-3',
        region_label: 'Jakarta (ap-southeast-3)',
        package: 'Advanced',
      },
      {
        region_key: 285,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-1',
        region_label: 'Mumbai (ap-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 285,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-1',
        region_label: 'Mumbai (ap-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 286,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-2',
        region_label: 'Hyderabad (ap-south-2)',
        package: 'Essentials',
      },
      {
        region_key: 286,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-south-2',
        region_label: 'Hyderabad (ap-south-2)',
        package: 'Advanced',
      },
      {
        region_key: 287,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-2',
        region_label: 'Seoul (ap-northeast-2)',
        package: 'Essentials',
      },
      {
        region_key: 287,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-2',
        region_label: 'Seoul (ap-northeast-2)',
        package: 'Advanced',
      },
      {
        region_key: 288,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-1',
        region_label: 'Tokyo (ap-northeast-1)',
        package: 'Essentials',
      },
      {
        region_key: 288,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-1',
        region_label: 'Tokyo (ap-northeast-1)',
        package: 'Advanced',
      },
      {
        region_key: 289,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-3',
        region_label: 'Osaka (ap-northeast-3)',
        package: 'Essentials',
      },
      {
        region_key: 289,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-northeast-3',
        region_label: 'Osaka (ap-northeast-3)',
        package: 'Advanced',
      },
      {
        region_key: 290,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-east-1',
        region_label: 'Hong Kong (ap-east-1)',
        package: 'Essentials',
      },
      {
        region_key: 290,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-east-1',
        region_label: 'Hong Kong (ap-east-1)',
        package: 'Advanced',
      },
      {
        region_key: 291,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-south-1',
        region_label: 'Bahrain (me-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 291,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-south-1',
        region_label: 'Bahrain (me-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 292,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-central-1',
        region_label: 'UAE (me-central-1)',
        package: 'Essentials',
      },
      {
        region_key: 292,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'me-central-1',
        region_label: 'UAE (me-central-1)',
        package: 'Advanced',
      },
      {
        region_key: 293,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'af-south-1',
        region_label: 'Cape Town (af-south-1)',
        package: 'Essentials',
      },
      {
        region_key: 293,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'af-south-1',
        region_label: 'Cape Town (af-south-1)',
        package: 'Advanced',
      },
      {
        region_key: 294,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-4',
        region_label: 'Melbourne (ap-southeast-4)',
        package: 'Essentials',
      },
      {
        region_key: 294,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ap-southeast-4',
        region_label: 'Melbourne (ap-southeast-4)',
        package: 'Advanced',
      },
      {
        region_key: 295,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'il-central-1',
        region_label: 'Tel Aviv (il-central1)',
        package: 'Essentials',
      },
      {
        region_key: 295,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'il-central-1',
        region_label: 'Tel Aviv (il-central1)',
        package: 'Advanced',
      },
      {
        region_key: 296,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-west-1',
        region_label: 'Calgary (ca-west-1)',
        package: 'Essentials',
      },
      {
        region_key: 296,
        provider: 'aws',
        provider_label: 'AWS',
        region: 'ca-west-1',
        region_label: 'Calgary (ca-west-1)',
        package: 'Advanced',
      },
      {
        region_key: 297,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-central1',
        region_label: 'Iowa (us-central1)',
        package: 'Essentials',
      },
      {
        region_key: 297,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-central1',
        region_label: 'Iowa (us-central1)',
        package: 'Advanced',
      },
      {
        region_key: 298,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west1',
        region_label: 'Oregon (us-west1)',
        package: 'Essentials',
      },
      {
        region_key: 298,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west1',
        region_label: 'Oregon (us-west1)',
        package: 'Advanced',
      },
      {
        region_key: 299,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east1',
        region_label: 'S. Carolina (us-east1)',
        package: 'Essentials',
      },
      {
        region_key: 299,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east1',
        region_label: 'S. Carolina (us-east1)',
        package: 'Advanced',
      },
      {
        region_key: 300,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east4',
        region_label: 'N. Virginia (us-east4)',
        package: 'Essentials',
      },
      {
        region_key: 300,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-east4',
        region_label: 'N. Virginia (us-east4)',
        package: 'Advanced',
      },
      {
        region_key: 301,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west2',
        region_label: 'Los Angeles (us-west2)',
        package: 'Essentials',
      },
      {
        region_key: 301,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west2',
        region_label: 'Los Angeles (us-west2)',
        package: 'Advanced',
      },
      {
        region_key: 302,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west4',
        region_label: 'Las Vegas (us-west4)',
        package: 'Essentials',
      },
      {
        region_key: 302,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'us-west4',
        region_label: 'Las Vegas (us-west4)',
        package: 'Advanced',
      },
      {
        region_key: 303,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast1',
        region_label: 'Montreal (northamerica-northeast1)',
        package: 'Essentials',
      },
      {
        region_key: 303,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast1',
        region_label: 'Montreal (northamerica-northeast1)',
        package: 'Advanced',
      },
      {
        region_key: 304,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast2',
        region_label: 'Toronto (northamerica-northeast2)',
        package: 'Essentials',
      },
      {
        region_key: 304,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'northamerica-northeast2',
        region_label: 'Toronto (northamerica-northeast2)',
        package: 'Advanced',
      },
      {
        region_key: 305,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-east1',
        region_label: 'Sao Paulo (southamerica-east1)',
        package: 'Essentials',
      },
      {
        region_key: 305,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-east1',
        region_label: 'Sao Paulo (southamerica-east1)',
        package: 'Advanced',
      },
      {
        region_key: 306,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-west1',
        region_label: 'Santiago (southamerica-west1)',
        package: 'Essentials',
      },
      {
        region_key: 306,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'southamerica-west1',
        region_label: 'Santiago (southamerica-west1)',
        package: 'Advanced',
      },
      {
        region_key: 307,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west1',
        region_label: 'Belgium (europe-west1)',
        package: 'Essentials',
      },
      {
        region_key: 307,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west1',
        region_label: 'Belgium (europe-west1)',
        package: 'Advanced',
      },
      {
        region_key: 308,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west2',
        region_label: 'London (europe-west2)',
        package: 'Essentials',
      },
      {
        region_key: 308,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west2',
        region_label: 'London (europe-west2)',
        package: 'Advanced',
      },
      {
        region_key: 309,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west3',
        region_label: 'Frankfurt (europe-west3)',
        package: 'Essentials',
      },
      {
        region_key: 309,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west3',
        region_label: 'Frankfurt (europe-west3)',
        package: 'Advanced',
      },
      {
        region_key: 310,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west4',
        region_label: 'Netherlands (europe-west4)',
        package: 'Essentials',
      },
      {
        region_key: 310,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west4',
        region_label: 'Netherlands (europe-west4)',
        package: 'Advanced',
      },
      {
        region_key: 311,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west6',
        region_label: 'Zurich (europe-west6)',
        package: 'Essentials',
      },
      {
        region_key: 311,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west6',
        region_label: 'Zurich (europe-west6)',
        package: 'Advanced',
      },
      {
        region_key: 312,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west8',
        region_label: 'Milan (europe-west8)',
        package: 'Essentials',
      },
      {
        region_key: 312,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west8',
        region_label: 'Milan (europe-west8)',
        package: 'Advanced',
      },
      {
        region_key: 313,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-north1',
        region_label: 'Finland (europe-north1)',
        package: 'Essentials',
      },
      {
        region_key: 313,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-north1',
        region_label: 'Finland (europe-north1)',
        package: 'Advanced',
      },
      {
        region_key: 314,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-central2',
        region_label: 'Warsaw (europe-central2)',
        package: 'Essentials',
      },
      {
        region_key: 314,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-central2',
        region_label: 'Warsaw (europe-central2)',
        package: 'Advanced',
      },
      {
        region_key: 315,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-southwest1',
        region_label: 'Madrid (europe-southwest1)',
        package: 'Essentials',
      },
      {
        region_key: 315,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-southwest1',
        region_label: 'Madrid (europe-southwest1)',
        package: 'Advanced',
      },
      {
        region_key: 316,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south1',
        region_label: 'Mumbai (asia-south1)',
        package: 'Essentials',
      },
      {
        region_key: 316,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south1',
        region_label: 'Mumbai (asia-south1)',
        package: 'Advanced',
      },
      {
        region_key: 317,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south2',
        region_label: 'Delhi (asia-south2)',
        package: 'Essentials',
      },
      {
        region_key: 317,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-south2',
        region_label: 'Delhi (asia-south2)',
        package: 'Advanced',
      },
      {
        region_key: 318,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east1',
        region_label: 'Taiwan (asia-east1)',
        package: 'Essentials',
      },
      {
        region_key: 318,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east1',
        region_label: 'Taiwan (asia-east1)',
        package: 'Advanced',
      },
      {
        region_key: 319,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east2',
        region_label: 'Hong Kong (asia-east2)',
        package: 'Essentials',
      },
      {
        region_key: 319,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-east2',
        region_label: 'Hong Kong (asia-east2)',
        package: 'Advanced',
      },
      {
        region_key: 320,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast1',
        region_label: 'Tokyo (asia-northeast1)',
        package: 'Essentials',
      },
      {
        region_key: 320,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast1',
        region_label: 'Tokyo (asia-northeast1)',
        package: 'Advanced',
      },
      {
        region_key: 321,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast2',
        region_label: 'Osaka (asia-northeast2)',
        package: 'Essentials',
      },
      {
        region_key: 321,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast2',
        region_label: 'Osaka (asia-northeast2)',
        package: 'Advanced',
      },
      {
        region_key: 322,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast3',
        region_label: 'Seoul (asia-northeast3)',
        package: 'Essentials',
      },
      {
        region_key: 322,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-northeast3',
        region_label: 'Seoul (asia-northeast3)',
        package: 'Advanced',
      },
      {
        region_key: 323,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast1',
        region_label: 'Singapore (asia-southeast1)',
        package: 'Essentials',
      },
      {
        region_key: 323,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast1',
        region_label: 'Singapore (asia-southeast1)',
        package: 'Advanced',
      },
      {
        region_key: 324,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast2',
        region_label: 'Jakarta (asia-southeast2)',
        package: 'Essentials',
      },
      {
        region_key: 324,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'asia-southeast2',
        region_label: 'Jakarta (asia-southeast2)',
        package: 'Advanced',
      },
      {
        region_key: 325,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast1',
        region_label: 'Sydney (australia-southeast1)',
        package: 'Essentials',
      },
      {
        region_key: 325,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast1',
        region_label: 'Sydney (australia-southeast1)',
        package: 'Advanced',
      },
      {
        region_key: 326,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast2',
        region_label: 'Melbourne(australia-southeast2)',
        package: 'Essentials',
      },
      {
        region_key: 326,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'australia-southeast2',
        region_label: 'Melbourne(australia-southeast2)',
        package: 'Advanced',
      },
      {
        region_key: 327,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-west1',
        region_label: 'Tel Aviv (me-west1)',
        package: 'Essentials',
      },
      {
        region_key: 327,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-west1',
        region_label: 'Tel Aviv (me-west1)',
        package: 'Advanced',
      },
      {
        region_key: 328,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west12',
        region_label: 'Turin (europe-west12)',
        package: 'Essentials',
      },
      {
        region_key: 328,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west12',
        region_label: 'Turin (europe-west12)',
        package: 'Advanced',
      },
      {
        region_key: 329,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west9',
        region_label: 'Paris (europe-west9)',
        package: 'Essentials',
      },
      {
        region_key: 329,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'europe-west9',
        region_label: 'Paris (europe-west9)',
        package: 'Advanced',
      },
      {
        region_key: 330,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central1',
        region_label: 'Doha (me-central1)',
        package: 'Essentials',
      },
      {
        region_key: 330,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central1',
        region_label: 'Doha (me-central1)',
        package: 'Advanced',
      },
      {
        region_key: 331,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central2',
        region_label: 'Dammam (me-central2)',
        package: 'Essentials',
      },
      {
        region_key: 331,
        provider: 'gcp',
        provider_label: 'GCP',
        region: 'me-central2',
        region_label: 'Dammam (me-central2)',
        package: 'Advanced',
      },
      {
        region_key: 332,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus',
        region_label: 'Virginia (eastus)',
        package: 'Essentials',
      },
      {
        region_key: 332,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus',
        region_label: 'Virginia (eastus)',
        package: 'Advanced',
      },
      {
        region_key: 333,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus2',
        region_label: 'Virginia (eastus2)',
        package: 'Essentials',
      },
      {
        region_key: 333,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastus2',
        region_label: 'Virginia (eastus2)',
        package: 'Advanced',
      },
      {
        region_key: 334,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus2',
        region_label: 'Washington (westus2)',
        package: 'Essentials',
      },
      {
        region_key: 334,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus2',
        region_label: 'Washington (westus2)',
        package: 'Advanced',
      },
      {
        region_key: 335,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus3',
        region_label: 'Phoenix (westus3)',
        package: 'Essentials',
      },
      {
        region_key: 335,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westus3',
        region_label: 'Phoenix (westus3)',
        package: 'Advanced',
      },
      {
        region_key: 336,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralus',
        region_label: 'Iowa (centralus)',
        package: 'Essentials',
      },
      {
        region_key: 336,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralus',
        region_label: 'Iowa (centralus)',
        package: 'Advanced',
      },
      {
        region_key: 337,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southcentralus',
        region_label: 'Texas (southcentralus)',
        package: 'Essentials',
      },
      {
        region_key: 337,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southcentralus',
        region_label: 'Texas (southcentralus)',
        package: 'Advanced',
      },
      {
        region_key: 338,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'canadacentral',
        region_label: 'Toronto (canadacentral)',
        package: 'Essentials',
      },
      {
        region_key: 338,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'canadacentral',
        region_label: 'Toronto (canadacentral)',
        package: 'Advanced',
      },
      {
        region_key: 339,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'brazilsouth',
        region_label: 'Sao Paulo state (brazilsouth)',
        package: 'Essentials',
      },
      {
        region_key: 339,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'brazilsouth',
        region_label: 'Sao Paulo state (brazilsouth)',
        package: 'Advanced',
      },
      {
        region_key: 340,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'northeurope',
        region_label: 'Ireland (northeurope)',
        package: 'Essentials',
      },
      {
        region_key: 340,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'northeurope',
        region_label: 'Ireland (northeurope)',
        package: 'Advanced',
      },
      {
        region_key: 341,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westeurope',
        region_label: 'Netherlands (westeurope)',
        package: 'Essentials',
      },
      {
        region_key: 341,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'westeurope',
        region_label: 'Netherlands (westeurope)',
        package: 'Advanced',
      },
      {
        region_key: 342,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'francecentral',
        region_label: 'Paris (francecentral)',
        package: 'Essentials',
      },
      {
        region_key: 342,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'francecentral',
        region_label: 'Paris (francecentral)',
        package: 'Advanced',
      },
      {
        region_key: 343,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'germanywestcentral',
        region_label: 'Frankfurt (germanywestcentral)',
        package: 'Essentials',
      },
      {
        region_key: 343,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'germanywestcentral',
        region_label: 'Frankfurt (germanywestcentral)',
        package: 'Advanced',
      },
      {
        region_key: 344,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uksouth',
        region_label: 'London (uksouth)',
        package: 'Essentials',
      },
      {
        region_key: 344,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uksouth',
        region_label: 'London (uksouth)',
        package: 'Advanced',
      },
      {
        region_key: 345,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'swedencentral',
        region_label: 'Gävle (swedencentral)',
        package: 'Essentials',
      },
      {
        region_key: 345,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'swedencentral',
        region_label: 'Gävle (swedencentral)',
        package: 'Advanced',
      },
      {
        region_key: 346,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'norwayeast',
        region_label: 'Oslo (norwayeast)',
        package: 'Essentials',
      },
      {
        region_key: 346,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'norwayeast',
        region_label: 'Oslo (norwayeast)',
        package: 'Advanced',
      },
      {
        region_key: 347,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'switzerlandnorth',
        region_label: 'Zurich (switzerlandnorth)',
        package: 'Essentials',
      },
      {
        region_key: 347,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'switzerlandnorth',
        region_label: 'Zurich (switzerlandnorth)',
        package: 'Advanced',
      },
      {
        region_key: 348,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southafricanorth',
        region_label: 'Johannesburg (southafricanorth)',
        package: 'Essentials',
      },
      {
        region_key: 348,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southafricanorth',
        region_label: 'Johannesburg (southafricanorth)',
        package: 'Advanced',
      },
      {
        region_key: 349,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uaenorth',
        region_label: 'Dubai (uaenorth)',
        package: 'Essentials',
      },
      {
        region_key: 349,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'uaenorth',
        region_label: 'Dubai (uaenorth)',
        package: 'Advanced',
      },
      {
        region_key: 350,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'qatarcentral',
        region_label: 'Qatar (qatarcentral)',
        package: 'Essentials',
      },
      {
        region_key: 350,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'qatarcentral',
        region_label: 'Qatar (qatarcentral)',
        package: 'Advanced',
      },
      {
        region_key: 351,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southeastasia',
        region_label: 'Singapore (southeastasia)',
        package: 'Essentials',
      },
      {
        region_key: 351,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'southeastasia',
        region_label: 'Singapore (southeastasia)',
        package: 'Advanced',
      },
      {
        region_key: 352,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralindia',
        region_label: 'Pune (centralindia)',
        package: 'Essentials',
      },
      {
        region_key: 352,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'centralindia',
        region_label: 'Pune (centralindia)',
        package: 'Advanced',
      },
      {
        region_key: 353,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'koreacentral',
        region_label: 'Seoul (koreacentral)',
        package: 'Essentials',
      },
      {
        region_key: 353,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'koreacentral',
        region_label: 'Seoul (koreacentral)',
        package: 'Advanced',
      },
      {
        region_key: 354,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastasia',
        region_label: 'Hong Kong (eastasia)',
        package: 'Essentials',
      },
      {
        region_key: 354,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'eastasia',
        region_label: 'Hong Kong (eastasia)',
        package: 'Advanced',
      },
      {
        region_key: 355,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'japaneast',
        region_label: 'Tokyo (japaneast)',
        package: 'Essentials',
      },
      {
        region_key: 355,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'japaneast',
        region_label: 'Tokyo (japaneast)',
        package: 'Advanced',
      },
      {
        region_key: 356,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'australiaeast',
        region_label: 'New South Wales (australiaeast)',
        package: 'Essentials',
      },
      {
        region_key: 356,
        provider: 'azure',
        provider_label: 'Azure',
        region: 'australiaeast',
        region_label: 'New South Wales (australiaeast)',
        package: 'Advanced',
      },
    ],
  },
};

export const FLINK_POOL_PARAMS_HACK = {
  region_details: [
    {
      provider: 'gcp',
      region: [
        {
          region_id: 659,
          region_name: 'northamerica-northeast1',
          region_label: 'Montreal (northamerica-northeast1)',
        },
        {
          region_id: 671,
          region_name: 'europe-southwest1',
          region_label: 'Madrid (europe-southwest1)',
        },
        {
          region_id: 685,
          region_name: 'europe-west9',
          region_label: 'Paris (europe-west9)',
        },
        {
          region_id: 673,
          region_name: 'asia-south2',
          region_label: 'Delhi (asia-south2)',
        },
        {
          region_id: 667,
          region_name: 'europe-west6',
          region_label: 'Zurich (europe-west6)',
        },
        {
          region_id: 680,
          region_name: 'asia-southeast2',
          region_label: 'Jakarta (asia-southeast2)',
        },
        {
          region_id: 664,
          region_name: 'europe-west2',
          region_label: 'London (europe-west2)',
        },
        {
          region_id: 668,
          region_name: 'europe-west8',
          region_label: 'Milan (europe-west8)',
        },
        {
          region_id: 674,
          region_name: 'asia-east1',
          region_label: 'Taiwan (asia-east1)',
        },
        {
          region_id: 675,
          region_name: 'asia-east2',
          region_label: 'Hong Kong (asia-east2)',
        },
        {
          region_id: 679,
          region_name: 'asia-southeast1',
          region_label: 'Singapore (asia-southeast1)',
        },
        {
          region_id: 687,
          region_name: 'me-central2',
          region_label: 'Dammam (me-central2)',
        },
        {
          region_id: 661,
          region_name: 'southamerica-east1',
          region_label: 'Sao Paulo (southamerica-east1)',
        },
        {
          region_id: 663,
          region_name: 'europe-west1',
          region_label: 'Belgium (europe-west1)',
        },
        {
          region_id: 678,
          region_name: 'asia-northeast3',
          region_label: 'Seoul (asia-northeast3)',
        },
        {
          region_id: 683,
          region_name: 'me-west1',
          region_label: 'Tel Aviv (me-west1)',
        },
        {
          region_id: 662,
          region_name: 'southamerica-west1',
          region_label: 'Santiago (southamerica-west1)',
        },
        {
          region_id: 669,
          region_name: 'europe-north1',
          region_label: 'Finland (europe-north1)',
        },
        {
          region_id: 660,
          region_name: 'northamerica-northeast2',
          region_label: 'Toronto (northamerica-northeast2)',
        },
        {
          region_id: 676,
          region_name: 'asia-northeast1',
          region_label: 'Tokyo (asia-northeast1)',
        },
        {
          region_id: 686,
          region_name: 'me-central1',
          region_label: 'Doha (me-central1)',
        },
        {
          region_id: 657,
          region_name: 'us-west2',
          region_label: 'Los Angeles (us-west2)',
        },
        {
          region_id: 658,
          region_name: 'us-west4',
          region_label: 'Las Vegas (us-west4)',
        },
        {
          region_id: 666,
          region_name: 'europe-west4',
          region_label: 'Netherlands (europe-west4)',
        },
        {
          region_id: 681,
          region_name: 'australia-southeast1',
          region_label: 'Sydney (australia-southeast1)',
        },
        {
          region_id: 654,
          region_name: 'us-west1',
          region_label: 'Oregon (us-west1)',
        },
        {
          region_id: 655,
          region_name: 'us-east1',
          region_label: 'S. Carolina (us-east1)',
        },
        {
          region_id: 672,
          region_name: 'asia-south1',
          region_label: 'Mumbai (asia-south1)',
        },
        {
          region_id: 677,
          region_name: 'asia-northeast2',
          region_label: 'Osaka (asia-northeast2)',
        },
        {
          region_id: 670,
          region_name: 'europe-central2',
          region_label: 'Warsaw (europe-central2)',
        },
        {
          region_id: 653,
          region_name: 'us-central1',
          region_label: 'Iowa (us-central1)',
        },
        {
          region_id: 665,
          region_name: 'europe-west3',
          region_label: 'Frankfurt (europe-west3)',
        },
        {
          region_id: 656,
          region_name: 'us-east4',
          region_label: 'N. Virginia (us-east4)',
        },
        {
          region_id: 682,
          region_name: 'australia-southeast2',
          region_label: 'Melbourne(australia-southeast2)',
        },
        {
          region_id: 684,
          region_name: 'europe-west12',
          region_label: 'Turin (europe-west12)',
        },
      ],
    },
    {
      provider: 'aws',
      region: [
        {
          region_id: 630,
          region_name: 'eu-central-1',
          region_label: 'Frankfurt (eu-central-1)',
        },
        {
          region_id: 640,
          region_name: 'ap-southeast-3',
          region_label: 'Jakarta (ap-southeast-3)',
        },
        {
          region_id: 642,
          region_name: 'ap-south-2',
          region_label: 'Hyderabad (ap-south-2)',
        },
        {
          region_id: 649,
          region_name: 'af-south-1',
          region_label: 'Cape Town (af-south-1)',
        },
        {
          region_id: 650,
          region_name: 'ap-southeast-4',
          region_label: 'Melbourne (ap-southeast-4)',
        },
        {
          region_id: 625,
          region_name: 'us-east-2',
          region_label: 'Ohio (us-east-2)',
        },
        {
          region_id: 639,
          region_name: 'ap-southeast-1',
          region_label: 'Singapore (ap-southeast-1)',
        },
        {
          region_id: 645,
          region_name: 'ap-northeast-3',
          region_label: 'Osaka (ap-northeast-3)',
        },
        {
          region_id: 647,
          region_name: 'me-south-1',
          region_label: 'Bahrain (me-south-1)',
        },
        {
          region_id: 631,
          region_name: 'eu-central-2',
          region_label: 'Zurich (eu-central-2)',
        },
        {
          region_id: 638,
          region_name: 'ap-southeast-2',
          region_label: 'Sydney (ap-southeast-2)',
        },
        {
          region_id: 646,
          region_name: 'ap-east-1',
          region_label: 'Hong Kong (ap-east-1)',
        },
        {
          region_id: 629,
          region_name: 'eu-west-1',
          region_label: 'Ireland (eu-west-1)',
        },
        {
          region_id: 652,
          region_name: 'ca-west-1',
          region_label: 'Calgary (ca-west-1)',
        },
        {
          region_id: 636,
          region_name: 'eu-south-2',
          region_label: 'Spain (eu-south-2)',
        },
        {
          region_id: 633,
          region_name: 'eu-west-3',
          region_label: 'Paris (eu-west-3)',
        },
        {
          region_id: 641,
          region_name: 'ap-south-1',
          region_label: 'Mumbai (ap-south-1)',
        },
        {
          region_id: 644,
          region_name: 'ap-northeast-1',
          region_label: 'Tokyo (ap-northeast-1)',
        },
        {
          region_id: 634,
          region_name: 'eu-north-1',
          region_label: 'Stockholm (eu-north-1)',
        },
        {
          region_id: 648,
          region_name: 'me-central-1',
          region_label: 'UAE (me-central-1)',
        },
        {
          region_id: 637,
          region_name: 'sa-east-1',
          region_label: 'São Paulo (sa-east-1)',
        },
        {
          region_id: 624,
          region_name: 'us-east-1',
          region_label: 'N. Virginia (us-east-1)',
        },
        {
          region_id: 632,
          region_name: 'eu-west-2',
          region_label: 'London (eu-west-2)',
        },
        {
          region_id: 635,
          region_name: 'eu-south-1',
          region_label: 'Milan (eu-south-1)',
        },
        {
          region_id: 626,
          region_name: 'us-west-2',
          region_label: 'Oregon (us-west-2)',
        },
        {
          region_id: 628,
          region_name: 'ca-central-1',
          region_label: 'Canada (ca-central-1)',
        },
        {
          region_id: 643,
          region_name: 'ap-northeast-2',
          region_label: 'Seoul (ap-northeast-2)',
        },
        {
          region_id: 651,
          region_name: 'il-central-1',
          region_label: 'Tel Aviv (il-central1)',
        },
      ],
    },
    {
      provider: 'azure',
      region: [
        {
          region_id: 701,
          region_name: 'swedencentral',
          region_label: 'Gävle (swedencentral)',
        },
        {
          region_id: 704,
          region_name: 'southafricanorth',
          region_label: 'Johannesburg (southafricanorth)',
        },
        {
          region_id: 711,
          region_name: 'japaneast',
          region_label: 'Tokyo (japaneast)',
        },
        {
          region_id: 697,
          region_name: 'westeurope',
          region_label: 'Netherlands (westeurope)',
        },
        {
          region_id: 703,
          region_name: 'switzerlandnorth',
          region_label: 'Zurich (switzerlandnorth)',
        },
        {
          region_id: 694,
          region_name: 'canadacentral',
          region_label: 'Toronto (canadacentral)',
        },
        {
          region_id: 706,
          region_name: 'qatarcentral',
          region_label: 'Qatar (qatarcentral)',
        },
        {
          region_id: 691,
          region_name: 'westus3',
          region_label: 'Phoenix (westus3)',
        },
        {
          region_id: 688,
          region_name: 'eastus',
          region_label: 'Virginia (eastus)',
        },
        {
          region_id: 696,
          region_name: 'northeurope',
          region_label: 'Ireland (northeurope)',
        },
        {
          region_id: 709,
          region_name: 'koreacentral',
          region_label: 'Seoul (koreacentral)',
        },
        {
          region_id: 700,
          region_name: 'uksouth',
          region_label: 'London (uksouth)',
        },
        {
          region_id: 712,
          region_name: 'australiaeast',
          region_label: 'New South Wales (australiaeast)',
        },
        {
          region_id: 710,
          region_name: 'eastasia',
          region_label: 'Hong Kong (eastasia)',
        },
        {
          region_id: 707,
          region_name: 'southeastasia',
          region_label: 'Singapore (southeastasia)',
        },
        {
          region_id: 705,
          region_name: 'uaenorth',
          region_label: 'Dubai (uaenorth)',
        },
        {
          region_id: 690,
          region_name: 'westus2',
          region_label: 'Washington (westus2)',
        },
        {
          region_id: 693,
          region_name: 'southcentralus',
          region_label: 'Texas (southcentralus)',
        },
        {
          region_id: 689,
          region_name: 'eastus2',
          region_label: 'Virginia (eastus2)',
        },
        {
          region_id: 695,
          region_name: 'brazilsouth',
          region_label: 'Sao Paulo state (brazilsouth)',
        },
        {
          region_id: 692,
          region_name: 'centralus',
          region_label: 'Iowa (centralus)',
        },
        {
          region_id: 699,
          region_name: 'germanywestcentral',
          region_label: 'Frankfurt (germanywestcentral)',
        },
        {
          region_id: 702,
          region_name: 'norwayeast',
          region_label: 'Oslo (norwayeast)',
        },
        {
          region_id: 708,
          region_name: 'centralindia',
          region_label: 'Pune (centralindia)',
        },
        {
          region_id: 698,
          region_name: 'francecentral',
          region_label: 'Paris (francecentral)',
        },
      ],
    },
  ],
  flink_pool_params: [
    {
      provider: 'aws',
      provider_label: 'AWS',
    },
    {
      provider: 'azure',
      provider_label: 'Azure',
    },
    {
      provider: 'gcp',
      provider_label: 'GCP',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
    },
    {
      provider: 'azure',
      provider_label: 'Azure',
    },
    {
      provider: 'gcp',
      provider_label: 'GCP',
    },
  ],
};

export const CLUSTER_PARAMS_HACK = {
  cluster_params: [
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'internet',
      networking_type_label: 'Internet',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'private_link',
      networking_type_label: 'Private Link',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'transit_gateway',
      networking_type_label: 'Transit Gateway',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'internet',
      networking_type_label: 'Internet',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'private_link',
      networking_type_label: 'Private Link',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'transit_gateway',
      networking_type_label: 'Transit Gateway',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'enterprise',
      cluster_type_label: 'Enterprise',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'private_link',
      networking_type_label: 'Private Link',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'standard',
      cluster_type_label: 'Standard',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'internet',
      networking_type_label: 'Internet',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'basic',
      cluster_type_label: 'Basic',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'internet',
      networking_type_label: 'Internet',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'azure',
      provider_label: 'Azure',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'internet',
      networking_type_label: 'Internet',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'azure',
      provider_label: 'Azure',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'azure',
      provider_label: 'Azure',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'private_link',
      networking_type_label: 'Private Link',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'azure',
      provider_label: 'Azure',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'internet',
      networking_type_label: 'Internet',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'azure',
      provider_label: 'Azure',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'azure',
      provider_label: 'Azure',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'private_link',
      networking_type_label: 'Private Link',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'azure',
      provider_label: 'Azure',
      cluster_type: 'enterprise',
      cluster_type_label: 'Enterprise',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'private_link',
      networking_type_label: 'Private Link',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'azure',
      provider_label: 'Azure',
      cluster_type: 'standard',
      cluster_type_label: 'Standard',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'internet',
      networking_type_label: 'Internet',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'azure',
      provider_label: 'Azure',
      cluster_type: 'basic',
      cluster_type_label: 'Basic',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'internet',
      networking_type_label: 'Internet',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'gcp',
      provider_label: 'GCP',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'internet',
      networking_type_label: 'Internet',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'gcp',
      provider_label: 'GCP',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'gcp',
      provider_label: 'GCP',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'private_link',
      networking_type_label: 'Private Link',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'gcp',
      provider_label: 'GCP',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'internet',
      networking_type_label: 'Internet',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'gcp',
      provider_label: 'GCP',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'gcp',
      provider_label: 'GCP',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'private_link',
      networking_type_label: 'Private Link',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'gcp',
      provider_label: 'GCP',
      cluster_type: 'standard',
      cluster_type_label: 'Standard',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'internet',
      networking_type_label: 'Internet',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'gcp',
      provider_label: 'GCP',
      cluster_type: 'basic',
      cluster_type_label: 'Basic',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'internet',
      networking_type_label: 'Internet',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: true,
      az_configuration: 'sz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: true,
      az_configuration: 'mz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'sz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'sz',
      az_config_label: 'Single Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: true,
      az_configuration: 'sz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: false,
      az_configuration: 'mz',
    },
    {
      provider: 'aws',
      provider_label: 'AWS',
      cluster_type: 'dedicated',
      cluster_type_label: 'Dedicated',
      az_config: 'mz',
      az_config_label: 'Multi Zone',
      networking_type: 'peered_vpc',
      networking_type_label: 'Peered VPC',
      data_transfer: 'inter_az',
      data_transfer_label: 'Inter AZ',
      is_follower_fetch_enabled: true,
      az_configuration: 'mz',
    },
  ],
  region_details: [
    {
      provider: 'gcp',
      region: [
        {
          region_id: 661,
          region_name: 'southamerica-east1',
          region_label: 'Sao Paulo (southamerica-east1)',
        },
        {
          region_id: 668,
          region_name: 'europe-west8',
          region_label: 'Milan (europe-west8)',
        },
        {
          region_id: 669,
          region_name: 'europe-north1',
          region_label: 'Finland (europe-north1)',
        },
        {
          region_id: 671,
          region_name: 'europe-southwest1',
          region_label: 'Madrid (europe-southwest1)',
        },
        {
          region_id: 675,
          region_name: 'asia-east2',
          region_label: 'Hong Kong (asia-east2)',
        },
        {
          region_id: 677,
          region_name: 'asia-northeast2',
          region_label: 'Osaka (asia-northeast2)',
        },
        {
          region_id: 666,
          region_name: 'europe-west4',
          region_label: 'Netherlands (europe-west4)',
        },
        {
          region_id: 685,
          region_name: 'europe-west9',
          region_label: 'Paris (europe-west9)',
        },
        {
          region_id: 681,
          region_name: 'australia-southeast1',
          region_label: 'Sydney (australia-southeast1)',
        },
        {
          region_id: 654,
          region_name: 'us-west1',
          region_label: 'Oregon (us-west1)',
        },
        {
          region_id: 655,
          region_name: 'us-east1',
          region_label: 'S. Carolina (us-east1)',
        },
        {
          region_id: 660,
          region_name: 'northamerica-northeast2',
          region_label: 'Toronto (northamerica-northeast2)',
        },
        {
          region_id: 663,
          region_name: 'europe-west1',
          region_label: 'Belgium (europe-west1)',
        },
        {
          region_id: 680,
          region_name: 'asia-southeast2',
          region_label: 'Jakarta (asia-southeast2)',
        },
        {
          region_id: 658,
          region_name: 'us-west4',
          region_label: 'Las Vegas (us-west4)',
        },
        {
          region_id: 673,
          region_name: 'asia-south2',
          region_label: 'Delhi (asia-south2)',
        },
        {
          region_id: 667,
          region_name: 'europe-west6',
          region_label: 'Zurich (europe-west6)',
        },
        {
          region_id: 657,
          region_name: 'us-west2',
          region_label: 'Los Angeles (us-west2)',
        },
        {
          region_id: 659,
          region_name: 'northamerica-northeast1',
          region_label: 'Montreal (northamerica-northeast1)',
        },
        {
          region_id: 674,
          region_name: 'asia-east1',
          region_label: 'Taiwan (asia-east1)',
        },
        {
          region_id: 656,
          region_name: 'us-east4',
          region_label: 'N. Virginia (us-east4)',
        },
        {
          region_id: 678,
          region_name: 'asia-northeast3',
          region_label: 'Seoul (asia-northeast3)',
        },
        {
          region_id: 662,
          region_name: 'southamerica-west1',
          region_label: 'Santiago (southamerica-west1)',
        },
        {
          region_id: 665,
          region_name: 'europe-west3',
          region_label: 'Frankfurt (europe-west3)',
        },
        {
          region_id: 670,
          region_name: 'europe-central2',
          region_label: 'Warsaw (europe-central2)',
        },
        {
          region_id: 679,
          region_name: 'asia-southeast1',
          region_label: 'Singapore (asia-southeast1)',
        },
        {
          region_id: 683,
          region_name: 'me-west1',
          region_label: 'Tel Aviv (me-west1)',
        },
        {
          region_id: 687,
          region_name: 'me-central2',
          region_label: 'Dammam (me-central2)',
        },
        {
          region_id: 653,
          region_name: 'us-central1',
          region_label: 'Iowa (us-central1)',
        },
        {
          region_id: 664,
          region_name: 'europe-west2',
          region_label: 'London (europe-west2)',
        },
        {
          region_id: 684,
          region_name: 'europe-west12',
          region_label: 'Turin (europe-west12)',
        },
        {
          region_id: 682,
          region_name: 'australia-southeast2',
          region_label: 'Melbourne(australia-southeast2)',
        },
        {
          region_id: 672,
          region_name: 'asia-south1',
          region_label: 'Mumbai (asia-south1)',
        },
        {
          region_id: 676,
          region_name: 'asia-northeast1',
          region_label: 'Tokyo (asia-northeast1)',
        },
        {
          region_id: 686,
          region_name: 'me-central1',
          region_label: 'Doha (me-central1)',
        },
      ],
    },
    {
      provider: 'azure',
      region: [
        {
          region_id: 691,
          region_name: 'westus3',
          region_label: 'Phoenix (westus3)',
        },
        {
          region_id: 704,
          region_name: 'southafricanorth',
          region_label: 'Johannesburg (southafricanorth)',
        },
        {
          region_id: 703,
          region_name: 'switzerlandnorth',
          region_label: 'Zurich (switzerlandnorth)',
        },
        {
          region_id: 705,
          region_name: 'uaenorth',
          region_label: 'Dubai (uaenorth)',
        },
        {
          region_id: 709,
          region_name: 'koreacentral',
          region_label: 'Seoul (koreacentral)',
        },
        {
          region_id: 695,
          region_name: 'brazilsouth',
          region_label: 'Sao Paulo state (brazilsouth)',
        },
        {
          region_id: 707,
          region_name: 'southeastasia',
          region_label: 'Singapore (southeastasia)',
        },
        {
          region_id: 712,
          region_name: 'australiaeast',
          region_label: 'New South Wales (australiaeast)',
        },
        {
          region_id: 689,
          region_name: 'eastus2',
          region_label: 'Virginia (eastus2)',
        },
        {
          region_id: 696,
          region_name: 'northeurope',
          region_label: 'Ireland (northeurope)',
        },
        {
          region_id: 697,
          region_name: 'westeurope',
          region_label: 'Netherlands (westeurope)',
        },
        {
          region_id: 702,
          region_name: 'norwayeast',
          region_label: 'Oslo (norwayeast)',
        },
        {
          region_id: 708,
          region_name: 'centralindia',
          region_label: 'Pune (centralindia)',
        },
        {
          region_id: 692,
          region_name: 'centralus',
          region_label: 'Iowa (centralus)',
        },
        {
          region_id: 711,
          region_name: 'japaneast',
          region_label: 'Tokyo (japaneast)',
        },
        {
          region_id: 698,
          region_name: 'francecentral',
          region_label: 'Paris (francecentral)',
        },
        {
          region_id: 701,
          region_name: 'swedencentral',
          region_label: 'Gävle (swedencentral)',
        },
        {
          region_id: 700,
          region_name: 'uksouth',
          region_label: 'London (uksouth)',
        },
        {
          region_id: 690,
          region_name: 'westus2',
          region_label: 'Washington (westus2)',
        },
        {
          region_id: 694,
          region_name: 'canadacentral',
          region_label: 'Toronto (canadacentral)',
        },
        {
          region_id: 706,
          region_name: 'qatarcentral',
          region_label: 'Qatar (qatarcentral)',
        },
        {
          region_id: 710,
          region_name: 'eastasia',
          region_label: 'Hong Kong (eastasia)',
        },
        {
          region_id: 693,
          region_name: 'southcentralus',
          region_label: 'Texas (southcentralus)',
        },
        {
          region_id: 699,
          region_name: 'germanywestcentral',
          region_label: 'Frankfurt (germanywestcentral)',
        },
        {
          region_id: 688,
          region_name: 'eastus',
          region_label: 'Virginia (eastus)',
        },
      ],
    },
    {
      provider: 'aws',
      region: [
        {
          region_id: 651,
          region_name: 'il-central-1',
          region_label: 'Tel Aviv (il-central1)',
        },
        {
          region_id: 631,
          region_name: 'eu-central-2',
          region_label: 'Zurich (eu-central-2)',
        },
        {
          region_id: 632,
          region_name: 'eu-west-2',
          region_label: 'London (eu-west-2)',
        },
        {
          region_id: 649,
          region_name: 'af-south-1',
          region_label: 'Cape Town (af-south-1)',
        },
        {
          region_id: 640,
          region_name: 'ap-southeast-3',
          region_label: 'Jakarta (ap-southeast-3)',
        },
        {
          region_id: 625,
          region_name: 'us-east-2',
          region_label: 'Ohio (us-east-2)',
        },
        {
          region_id: 639,
          region_name: 'ap-southeast-1',
          region_label: 'Singapore (ap-southeast-1)',
        },
        {
          region_id: 638,
          region_name: 'ap-southeast-2',
          region_label: 'Sydney (ap-southeast-2)',
        },
        {
          region_id: 641,
          region_name: 'ap-south-1',
          region_label: 'Mumbai (ap-south-1)',
        },
        {
          region_id: 652,
          region_name: 'ca-west-1',
          region_label: 'Calgary (ca-west-1)',
        },
        {
          region_id: 624,
          region_name: 'us-east-1',
          region_label: 'N. Virginia (us-east-1)',
        },
        {
          region_id: 633,
          region_name: 'eu-west-3',
          region_label: 'Paris (eu-west-3)',
        },
        {
          region_id: 643,
          region_name: 'ap-northeast-2',
          region_label: 'Seoul (ap-northeast-2)',
        },
        {
          region_id: 648,
          region_name: 'me-central-1',
          region_label: 'UAE (me-central-1)',
        },
        {
          region_id: 644,
          region_name: 'ap-northeast-1',
          region_label: 'Tokyo (ap-northeast-1)',
        },
        {
          region_id: 630,
          region_name: 'eu-central-1',
          region_label: 'Frankfurt (eu-central-1)',
        },
        {
          region_id: 646,
          region_name: 'ap-east-1',
          region_label: 'Hong Kong (ap-east-1)',
        },
        {
          region_id: 626,
          region_name: 'us-west-2',
          region_label: 'Oregon (us-west-2)',
        },
        {
          region_id: 629,
          region_name: 'eu-west-1',
          region_label: 'Ireland (eu-west-1)',
        },
        {
          region_id: 634,
          region_name: 'eu-north-1',
          region_label: 'Stockholm (eu-north-1)',
        },
        {
          region_id: 647,
          region_name: 'me-south-1',
          region_label: 'Bahrain (me-south-1)',
        },
        {
          region_id: 650,
          region_name: 'ap-southeast-4',
          region_label: 'Melbourne (ap-southeast-4)',
        },
        {
          region_id: 628,
          region_name: 'ca-central-1',
          region_label: 'Canada (ca-central-1)',
        },
        {
          region_id: 642,
          region_name: 'ap-south-2',
          region_label: 'Hyderabad (ap-south-2)',
        },
        {
          region_id: 635,
          region_name: 'eu-south-1',
          region_label: 'Milan (eu-south-1)',
        },
        {
          region_id: 645,
          region_name: 'ap-northeast-3',
          region_label: 'Osaka (ap-northeast-3)',
        },
        {
          region_id: 636,
          region_name: 'eu-south-2',
          region_label: 'Spain (eu-south-2)',
        },
        {
          region_id: 637,
          region_name: 'sa-east-1',
          region_label: 'São Paulo (sa-east-1)',
        },
      ],
    },
  ],
  full_connectors_list: {
    connector_types: [
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard_',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic_',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'customconnector_tier2gb',
        connector_label: 'CustomConnector_Tier2GB',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'transit_gateway',
        networking_type_label: 'Transit Gateway',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'transit_gateway',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'aws',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'azure',
        cluster_type: 'enterprise',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'basic',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'standard',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'internet',
        networking_type_label: 'Internet',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'internet',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'private_link',
        networking_type_label: 'Private Link',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'peered_vpc',
        networking_type_label: 'Peered VPC',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'peered_vpc',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_public',
        networking_type_label: 'Connect Public',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_public',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_peering',
        networking_type_label: 'Connect Peering',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_peering',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 's3_sink',
        connector_label: 'S3_SINK',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'gcssink',
        connector_label: 'GcsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureblobsink',
        connector_label: 'AzureBlobSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'kinesissource',
        connector_label: 'KinesisSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'pubsubsource',
        connector_label: 'PubSubSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureeventhubssource',
        connector_label: 'AzureEventHubsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'datagensource',
        connector_label: 'DatagenSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgressource',
        connector_label: 'PostgresSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlsource',
        connector_label: 'MySqlSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'microsoftsqlserversource',
        connector_label: 'MicrosoftSqlServerSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oracledatabasesource',
        connector_label: 'OracleDatabaseSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azuredatalakegen2sink',
        connector_label: 'AzureDataLakeGen2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'dataprocsink',
        connector_label: 'DataprocSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mongodbatlassource',
        connector_label: 'MongoDbAtlasSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigquerysink',
        connector_label: 'BigQuerySink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'redshiftsink',
        connector_label: 'RedshiftSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'spannersink',
        connector_label: 'SpannerSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'elasticsearchsink',
        connector_label: 'ElasticsearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mongodbatlassink',
        connector_label: 'MongoDbAtlasSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'snowflakesink',
        connector_label: 'SnowflakeSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'lambdasink',
        connector_label: 'LambdaSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcecdcsource',
        connector_label: 'SalesforceCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgrescdcsource',
        connector_label: 'PostgresCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlcdcsource',
        connector_label: 'MySqlCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqlservercdcsource',
        connector_label: 'SqlServerCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgressink',
        connector_label: 'PostgresSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'microsoftsqlserversink',
        connector_label: 'MicrosoftSqlServerSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azurefunctionssink',
        connector_label: 'AzureFunctionsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlsink',
        connector_label: 'MySqlSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'googlecloudfunctionssink',
        connector_label: 'GoogleCloudFunctionsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'datadogmetricssink',
        connector_label: 'DatadogMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsink',
        connector_label: 'HttpSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cosmosdbsink',
        connector_label: 'CosmosDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforceplatformeventsource',
        connector_label: 'SalesforcePlatformEventSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcesobjectsink',
        connector_label: 'SalesforceSObjectSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'servicenowsource',
        connector_label: 'ServiceNowSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'servicenowsink',
        connector_label: 'ServiceNowSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azuresqldwsink',
        connector_label: 'AzureSqlDwSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'dynamodbsink',
        connector_label: 'DynamoDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigtablesink',
        connector_label: 'BigTableSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'redissink',
        connector_label: 'RedisSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureservicebussource',
        connector_label: 'AzureServiceBusSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oracledatabasesink',
        connector_label: 'OracleDatabaseSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforceplatformeventsink',
        connector_label: 'SalesforcePlatformEventSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cloudwatchmetricssink',
        connector_label: 'CloudWatchMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azurecognitivesearchsink',
        connector_label: 'AzureCognitiveSearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'solacesink',
        connector_label: 'SolaceSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqssource',
        connector_label: 'SqsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcepushtopicsource',
        connector_label: 'SalesforcePushTopicSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cloudwatchlogssource',
        connector_label: 'CloudWatchLogsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapisource',
        connector_label: 'SalesforceBulkApiSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'githubsource',
        connector_label: 'GithubSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'databricksdeltalakesink',
        connector_label: 'DatabricksDeltaLakeSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mqttsource',
        connector_label: 'MqttSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mqttsink',
        connector_label: 'MqttSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'rabbitmqsource',
        connector_label: 'RabbitMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'rabbitmqsink',
        connector_label: 'RabbitMQSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sftpsource',
        connector_label: 'SftpSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sftpsink',
        connector_label: 'SftpSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'activemqsource',
        connector_label: 'ActiveMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'pagerdutysink',
        connector_label: 'PagerDutySink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'zendesksource',
        connector_label: 'ZendeskSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'splunksink',
        connector_label: 'SplunkSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'ibmmqsource',
        connector_label: 'IbmMQSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'cosmosdbsource',
        connector_label: 'CosmosDbSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 's3source',
        connector_label: 'S3Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'influxdb2sink',
        connector_label: 'InfluxDB2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'influxdb2source',
        connector_label: 'InfluxDB2Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'jirasource',
        connector_label: 'JiraSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'oraclecdcsource',
        connector_label: 'OracleCdcSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'gcssource',
        connector_label: 'GcsSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureblobsource',
        connector_label: 'AzureBlobSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'newrelicmetricssink',
        connector_label: 'NewRelicMetricsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsource',
        connector_label: 'HttpSource',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapiv2source',
        connector_label: 'SalesforceBulkApiV2Source',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'salesforcebulkapiv2sink',
        connector_label: 'SalesforceBulkApiV2Sink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'azureloganalyticssink',
        connector_label: 'AzureLogAnalyticsSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'alloydbsink',
        connector_label: 'AlloyDbSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'bigquerystoragesink',
        connector_label: 'BigQueryStorageSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsourcev2',
        connector_label: 'HttpSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'httpsinkv2',
        connector_label: 'HttpSinkV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'opensearchsink',
        connector_label: 'OpenSearchSink',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'mysqlcdcsourcev2',
        connector_label: 'MySqlCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'postgrescdcsourcev2',
        connector_label: 'PostgresCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
      {
        provider: 'gcp',
        cluster_type: 'dedicated',
        networking_type: 'connect_private_link',
        networking_type_label: 'Connect Private Link',
        connector_name: 'sqlservercdcsourcev2',
        connector_label: 'SqlServerCdcSourceV2',
        available_connector_networking_type: 'connect_private_link',
      },
    ],
  },
  connectorNameToRecordsMap: {},
  sla_configs: [
    {
      sla: 99.9,
      sla_label: '99.9%',
    },
    {
      sla: 99.99,
      sla_label: '99.99%',
    },
  ],
  cluster_sla_configs: [
    {
      cluster_type: 'enterprise',
      az_configuration: 'mz',
      sla: 99.99,
      sla_label: '99.99%',
    },
    {
      cluster_type: 'enterprise',
      az_configuration: 'mz',
      sla: 99.9,
      sla_label: '99.9%',
    },
    {
      cluster_type: 'dedicated',
      az_configuration: 'sz',
      sla: 99.95,
      sla_label: '99.95%',
    },
    {
      cluster_type: 'dedicated',
      az_configuration: 'mz',
      sla: 99.99,
      sla_label: '99.99%',
    },
    {
      cluster_type: 'basic',
      az_configuration: 'mz',
      sla: 99.5,
      sla_label: '99.5%',
    },
    {
      cluster_type: 'standard',
      az_configuration: 'mz',
      sla: 99.99,
      sla_label: '99.99%',
    },
    {
      cluster_type: 'standard',
      az_configuration: 'mz',
      sla: 99.9,
      sla_label: '99.9%',
    },
  ],
};

export const SP_COMMIT_CONFIGS = {
  deal_types: ['New Commit', 'Renewal'],
  marketplaces: ['None', 'AWS', 'GCP', 'Azure'],
  payment_schedules: ['Prepaid', 'Arrears'],
  support_tiers: ['Free', 'Developer', 'Business', 'Premier'],
  rate_cards: [
    {
      id: 1,
      name: '8/1',
      label: 'Aug 1, 2020 (8/1) (Admins Only)',
      actionable: true,
    },
    {
      id: 2,
      name: '10/1',
      label: 'Oct 1, 2020 (10/1)',
      actionable: true,
    },
    {
      id: 3,
      name: '3/5/2024',
      label: 'Mar 5, 2024 (3/5/2024)',
      actionable: true,
    },
    {
      id: 4,
      name: '4/11/2024',
      label: 'Apr 11, 2024 (4/11/2024)',
      actionable: true,
    },
  ],
};

export const THREE_DOT_O_EARLY_ACCESS_LIST = [
  'gcunningham@confluent.io',
  'jwiederhold@confluent.io',
  'ssanku@confluent.io',
  'mkulkarni@confluent.io',
  'ksoma@confluent.io',
  'jyoti@confluent.io',
  'vgundamaraju@confluent.io',
  'dnidwannaya@confluent.io',
  'lgarg@confluent.io',
  'achauhan@confluent.io',
  'atomar@confluent.io',
  'nik@confluent.io',
  'rbhindwale@confluent.io',

  // UAT users
  'lmelodepaula@confluent.io',
  'bob.lehmann@confluent.io',
  'derek@confluent.io',
  'ewatkins@confluent.io',
  'cdurrang@confluent.io',
  'jimlee@confluent.io',
  'jholoman@confluent.io',
  'rmarkham@confluent.io',
  'hwood@confluent.io',
  'max.horgan@confluent.io',
  'luke@confluent.io',
  'lgalli@confluent.io',
  'oporus@confluent.io',
  'mberindetampanariu@confluent.io',
  'inesi@confluent.io',
  'sdellangelo@confluent.io',
  'pfomin@confluent.io',
  'olaplace@confluent.io',
  'ahan@confluent.io',
  'ssanku@confluent.io',
  'dchoudhry@confluent.io',
  'ctullysmith@confluent.io',
  'smorana@confluent.io',
  'tkotibhaskar@confluent.io',
  'jgollan@confluent.io',
  'ecukuroglu@confluent.io',
  'rvyas@confluent.io',
  'jnichols@confluent.io',
  'aong@confluent.io',
  'aellebracht@confluent.io',
  'ksm@confluent.io',
  'sjukanti@confluent.io',
  'vsalzman@confluent.io',
  'nle@confluent.io',
  'brian.walter@confluent.io',
  'vsalzman@confluent.io',
  'nle@confluent.io',
  'brian.walter@confluent.io',
  'msrinivasaragavan@confluent.io',
];
