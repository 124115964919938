// @flow

import {
  useGetSPDefaultCommitPricingStatusViewQuery,
  useSubmitRecalcRequestForDefaultCommitMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import React, { useCallback } from 'react';
import SPAsyncCalcWrapper from '@components/common/pricing-summary-wrapper/SPAsyncCalcWrapper';

import SPDefaultCommitMonthlySummaryContainerWrapper from './SPDefaultCommitMonthlySummaryContainerWrapper';

const SPDefaultCommitSummaryContainer = () => {
  const pricingStatusFetchingFunction = useCallback(
    useGetSPDefaultCommitPricingStatusViewQuery,
    []
  );

  return (
    <SPAsyncCalcWrapper
      pricingStatusFetchingFunction={pricingStatusFetchingFunction}
      useRecalcRequestMutation={useSubmitRecalcRequestForDefaultCommitMutation}
    >
      <SPDefaultCommitMonthlySummaryContainerWrapper addCostMargin={false} disabled={false} />
    </SPAsyncCalcWrapper>
  );
};

export default SPDefaultCommitSummaryContainer;
